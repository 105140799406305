import styled from "styled-components";

export const Container = styled.div<any>`
display: flex;
flex-direction: column;
align-items: center;
height: 100%;
overflow-y: auto;
  .column-cards {
    width: ${props => (props.isMobile ? '100%' : '80%')};
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    gap: 13px;
    margin-top: 1rem;
  }
`

export const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  .steps-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
      color: #3D3D3D;
      font-size: 12px;
      font-weight: 600;
      margin-top: 0.8rem;
    }
  }
`

export const Content = styled.div<any>`
  width: ${props => (props.isMobile ? '100%' : '80%')};
  margin-top: 1rem;
`


export const TableHeader = styled.div`
  background-color: #f6f6f6;
  border-radius: 4px;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 12px 48px 12px 33px;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }

  .product {
    width: 50%;
  }

  .product-properties {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: 30px;

    width: 50%;
  }
`

export const Products = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  width: ${props => props.isMobile ? '100%' : ''};
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`

export const Line = styled.span`
  margin-left: 5%;
  width: 90%;
  height: 1px;
  background: rgba(119, 119, 119, 0.5); ;
`


export const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 5px;
  height: 45px;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  width: 180px;
  cursor: pointer;
  `

  export const WrapperProduct = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 18px;
    padding: 8px;
    background: rgba(225, 225, 225, 0.3);
    margin: 8px 0;
    .product-image {
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
      }
    }
`

export const ModalOrderPaid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
  background: rgba(225, 225, 225, 0.3);
  margin: 8px 0;

  .icon-paid {
    font-size: 80px;
    color: #41AC47
  }

  .text-paid {
    padding: 10px;
  }

  h2 {
    color: #41AC47;
    margin-bottom: 16px;
  }

  h3 {
    margin-bottom: 16px;
  }

  b {
    color: #41AC47;
  }
`
