import React, { useMemo } from 'react'
import { Product } from './Product'
import * as Style from './styles'

interface Props {
  products: {
    id: number
    translated_name: string
    real_price: number
    media: {
      path: string
      format: string
    }[]
    quantity: 0
  }[]
}

export const ListProduct: any = ({ products }: Props) => {
  const rowRender = useMemo(
    () =>
      products.map(product => (
        <Style.Container key={product.id}>
          <Product
            price={product.real_price}
            image={
              product.media.length > 0
                ? product.media[0].path
                : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14516/foto%201.jpg'
            }
            translated_name={product.translated_name}
            id={product.id}
          />
        </Style.Container>
      )),
    [products]
  )
  return <Style.List>{rowRender}</Style.List>
}
