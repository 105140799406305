import { AiOutlineSearch } from "react-icons/ai"
import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { MOBILE_WIDTH } from "utils/constants"
import Logout from "./Logout"
import { Accordion } from "../Accordion"
import { RowTable } from "../RowTable"
import * as Styles from "./styles"


export const UserBar = () => {
  const { searchOrders } = useOrderB2c()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
      <Styles.Container isMobile={isMobile}>
        <Logout />
        <Styles.InputContainer isMobile={isMobile}>
          <input type="text" placeholder="Pesquisar" onChange={e => searchOrders(e.target.value)}/>
          <AiOutlineSearch />
        </Styles.InputContainer>
      </Styles.Container>
  )
}
