/* eslint-disable react/destructuring-assignment */
import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { useEffect } from "react"
import { BiXCircle } from "react-icons/bi"
import { MOBILE_WIDTH } from "utils/constants"
import * as Style from "./styles"

const options = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70
]
interface Item {
  id: number
  name: string
  real_price: number
  quantity: number
  image: string
}

export const Product = (product: Item) => {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { removeCart, setQuantity, couponIsValid, couponResponse, getDiscount, itens } = useOrderB2c()

  async function handleSelect(event: string, id: number) {
    if (parseInt(event, 10) === 0) {
      removeCart(product.id)
    } else {
      setQuantity(id, parseInt(event, 10))
    }
  }

  useEffect(() => {
    if (couponIsValid) {
      getDiscount(itens, couponResponse.coupon.name)
    }
  }, [product.quantity])

  // eslint-disable-next-line no-unsafe-optional-chaining
  const total = product?.real_price * product?.quantity
  return(
    <Style.Container key={product?.id} isMobile={isMobile}  className="animate__animated animate__fadeIn">
    <Style.ProductInfos isMobile={isMobile}>
      <img src={product?.image} alt="foto produto" />
      <Style.ProductName isMobile={isMobile}>{product?.name}</Style.ProductName>
    </Style.ProductInfos>

    <Style.ProductProperties isMobile={isMobile}>
      <Style.Quantity>
        <select value={product?.quantity} onChange={e => handleSelect(e.target.value, product?.id)}>
          {options.map(option => (
            <option key={option}>{option}</option>
          ))}
        </select>
      </Style.Quantity>
      <Style.ProductPrice isMobile={isMobile}>
        {isMobile && <span>VALOR UNITÁRIO</span>}
        {new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
          currencyDisplay: 'symbol'
        }).format(product?.real_price)}
      </Style.ProductPrice>
      <Style.ProductTotal isMobile={isMobile}>
        {isMobile && <span>VALOR TOTAL</span>}

        {new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
          currencyDisplay: 'symbol'
        }).format(total)}
      </Style.ProductTotal>
    </Style.ProductProperties>
    <div className="close-btn">
        {isMobile ? (
          <button type="button" className="btn-remove">remover</button>
        ) : (
        <BiXCircle size={20} onClick={() => removeCart(product?.id)}/>
        )}
    </div>
  </Style.Container>
  )
}
