import { SUBDOMAINS } from './constants'

export const getApp = () => {
  const subdomain = getSubdomain(window.location.hostname)

  const main = SUBDOMAINS.find(app => app.main)

  if (!main) throw new Error('Must have main app')

  if (subdomain === '') return main.app

  const app = SUBDOMAINS.find(app => subdomain === app.subdomain)
  if (!app) return main.app

  return app.subdomain
}

export const getBrandId = () => {
  const subdomain = getSubdomain(window.location.hostname)

  const main = SUBDOMAINS.find(app => app.main)

  if (!main) throw new Error('Must have main app')

  if (subdomain === '') return main.app

  const app = SUBDOMAINS.find(app => subdomain === app.subdomain)
  console.log(app)

  if (!app) return main.app

  return app.id
}

const getSubdomain = (location: string) => {
  if (location.includes('dev')) {
    const locationParts = location.split('-')

    const brandName = locationParts[1].split('.')

    return brandName[0]
  }
  const locationParts = location.split('.')

  let sliceTill = -2

  const isLocalhost = locationParts.slice(-1)[0] === 'localhost'

  if (isLocalhost) sliceTill = -1

  return locationParts.slice(0, sliceTill).join('')
}

export const getTitle = () => {
  const subdomain = getSubdomain(window.location.hostname)

  const main = SUBDOMAINS.find(app => app.main)

  if (!main) throw new Error('Must have main app')

  if (subdomain === '') return main.app

  const app = SUBDOMAINS.find(app => subdomain === app.subdomain)
  console.log(app)

  if (!app) return main.app

  return app.title
}
