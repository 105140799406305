import styled from "styled-components";

export const Container = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  background-color: ${(props) => props.alreadyInCart ? '#DDDDDD' : '#f6f6f6'} ;
  cursor: ${(props) => props.alreadyInCart ? 'not-allowed' : 'pointer'};

  img{
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .product-name-image{
    display: flex;
    align-items: center;
    p{
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      margin-left: 1rem;
      color:${(props) => props.alreadyInCart ? '#818181' : '#000000'};
    }
  }

  &:hover{
    background: ${props => props.theme.colors.hover}
  }

  .product-price{
    display: flex;
    align-items: center;
    width: 20%;
    .pipe{
      color: ${(props) => props.alreadyInCart ? '#818181' : '#12624D'};
      font-size: 22px;
      margin-right: auto;
    }

    .price{
      font-size: 14px;
      color: #646464;
    }
  }

  .action{
    display: flex;
    margin-left: 10px;
    color: #12624D;
    cursor: pointer;
  }
`
