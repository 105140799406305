import { useLayoutEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import AlertMessage from 'components/Alert';
import { PaymentMethod, useOrderB2c } from 'hooks/useOrderB2c';
import { QRCodeCanvas } from 'qrcode.react';
import { ClockCircleOutlined } from '@ant-design/icons'
import CountDown from 'pages/Checkout/PaymentMethod/Pix/CountDown';

import * as Style from './styles'

interface Props {
  payment: PaymentMethod
  generatePixInfo: any
  pixInfo: string
  error: boolean
  setPaymentMethod: any

}
  
export const Pix = ({ payment, generatePixInfo, pixInfo, error, setPaymentMethod }: Props) => {
  const [lineIsCopied, setLineIsCopied] = useState<boolean>(false)
  const [, setCopyClipboard] = useState<string>('')

  const { checkPixPaiment } = useOrderB2c()
  const {uuid} = useParams() as any
  const history = useHistory()


  const copyByClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)

    setCopyClipboard(text)
    setLineIsCopied(true)
  }

  setInterval(async () => {
    const response = await checkPixPaiment(uuid)
    if (response === "paid" || response === 'Pagamento Aprovado') {
      history.push(`/meu_pedido/${uuid}`)
    }
}, 30000)

useLayoutEffect(() => {
  if (pixInfo === '') {
	  generatePixInfo(uuid, payment)
  }
}, [])

  return (
    <Style.Container>
      {error && (
        <AlertMessage alertType="error" />
      )}
      {!error && (
        <>
          <Style.CountDownContainer>
          <ClockCircleOutlined style={{fontSize: "24px", paddingRight: "10px"}} />
          <Style.CountDownContent>
              <CountDown generatePixInfo={generatePixInfo} payment={payment} />
            <p>para expirar</p>
          </Style.CountDownContent>
          </Style.CountDownContainer>
          <Style.QrCodeContainer>
            <QRCodeCanvas
              value={pixInfo}
              size={200}
              bgColor="#f6f6f6"
              fgColor="#000000"
              level="L"
              includeMargin={false}
            />
            <Style.QrCodeinfos>
              <p>1. Copia a chave pix.</p>
              <p>2. Entre no aplicativo da sua instituição financeira e acesse o ambiente pix.</p>
              <p>3. Escolha a opção de Copia e Cola e insira o código Pix.</p>
              <p>4. Verifique as informações e confirme o pagamento.</p>
              <p>5. Após validado o pagamento você será automaticamente direcionado para a página de resumo de compra.</p>
            </Style.QrCodeinfos>
            </Style.QrCodeContainer>
          {!lineIsCopied ? (
            <Style.ButtonCopy onClick={()=> copyByClipboard(pixInfo)}>
              Copiar código
            </Style.ButtonCopy>
          ) : (
            <Style.ButtonCopied>
              Código copiado
            </Style.ButtonCopied>
          )}
      </>
      )}
    </Style.Container>
  )
}
