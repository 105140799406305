import React from 'react'
import ReactPaginate from 'react-paginate'
import * as Styles from './styles'

const Pagination = (props: any) => {
  return (
    <Styles.Container>
      <ReactPaginate previousLabel={0} nextLabel={0} {...props} />
    </Styles.Container>
  )
}

export default Pagination
