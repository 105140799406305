import styled, { css } from 'styled-components'
import { theme } from 'styles'

export const v = {
  sidebarWidth: `300px`,
  smSpacing: `8px`,
  mdSpacing: `16px`,
  lgSpacing: `24px`,
  xlSpacing: `32px`,
  xxlSpacing: `48px`,
  borderRadius: `6px`,
}

export const UserStyled = styled.div.attrs({
  className: 'bg-red-200',
})``

export const UserStyledTest = styled.div<any>`
  width: ${({ isOpen }) => (isOpen ? 'bg-red-200' : 'bg-red-200')};
`

export const SMain = styled.main`
  width: 100%;
  background-color: #f3f3f3;
  h1 {
    font-size: 14px;
  }
`

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  max-width: 1500px;
  padding: ${theme.spacing.space3};
  overflow-y: auto;

  .wrapper-container {
    height: 100%;
    width: 100%;
  }
`

export const LayoutContainer = styled.main`
  height: 100%;
  overflow-y: auto;
  padding: 20px;
`
