export function validCPF(value: string) {
	const validChars = /^[0-9.-]+$/;
	if (!value.match(validChars)) {
		return false
	}

	const cpf = value.replace(/\D/g, '');
	if (cpf.length !== 11) {
		return false
	}

	const unique = new Set(cpf.split(''));
	if (unique.size === 1) {
		return false;
	}

	let sum = 0;
	for (let i = 1; i <= 9; i += 1) {
		sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
	}

	let rest = (sum * 10) % 11;
	if ((rest === 10) || (rest === 11)) {
		rest = 0
	}
	if (rest !== parseInt(cpf.substring(9, 10), 10)) {
		return false
	}

	sum = 0
	for (let i = 1; i <= 10; i += 1) {
		sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
	}

	rest = (sum * 10) % 11;
	if ((rest === 10) || (rest === 11)) {
		rest = 0
	}

	if (rest !== parseInt(cpf.substring(10, 11), 10)) {
		return false;
	}

	return true
}

export function validCep(value: string) {
	const validChars = /^[0-9.-]+$/;
	if (!value.match(validChars)) {
		return false;
	}

	const cpf = value.replace(/\D/g, '');
	if (cpf.length !== 8) {
		return false;
	}

	return true;
}

export function validName(value: string) {
	const names = value.trim().split(' ');

	let isValid
  const nameArr = []

  if (names.length < 2) {
    isValid = false;
  }

  names.forEach(name => {
    nameArr.push(name)
	})

  if (nameArr.length >= 2) {
      isValid = true
    
    } else if (nameArr.length < 2) {
      isValid = false
    }

  return isValid
}


export function validEmail(value: string) {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export function validBirthDate(value: string) {
	const [day, mon, year] = value.split('/');
  const dayInt = parseInt(day, 10);
  const monInt = parseInt(mon, 10);
  const atualDate = new Date()
  const atualYear = atualDate.getFullYear()

  let validMsg;

  if (day && (day === '00' || dayInt > 31)) {
    validMsg = false;
  } else if (mon && (mon === '00' || monInt > 12)) {
    validMsg = false;
  } else if (Number(year) < 1900) {
   	validMsg = false;
  } else if (year && Number(year) > atualYear) {
    validMsg = false;
  } else {
		validMsg = true
	}
  return validMsg
} 

export function validPhone (value: string) {
	const validChars = /^[0-9()-\s]+$/;
	let isValid
  if (!value.match(validChars)) {
    isValid = false;
  }

  const phone = value.replace(/\D/g, '');

  if(phone[2] === '9' && phone.length === 11) {
    isValid = true;
  } else if ((phone[2] !== '9' && phone.length === 10)) {
    isValid = true;
  } else {
    isValid = false;
  }
	return isValid
}