import React, {useEffect} from 'react'
import useMediaQuery from 'hooks/useMediaQuery';
import { MOBILE_WIDTH, routes } from 'utils/constants';
import { useHistory } from 'react-router-dom';
import cannectB2bLogo from '../../assets/img/cannect-b2b.png'
import {themes} from "../../styles/theme/brand-theme";
import {getApp} from "../../utils/sub-domains";
import * as Styles from './styles'

const Menu = () => {

  const CurrentApp = getApp()

  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const history = useHistory()

  const redirectToHome = () => {
    history.push(routes.HOME)
  }

  return (
    <>
      <Styles.Container isMobile={isMobile}>
        <div className="content">
          <a href="/pedidos">
            <div className="imgbx">
              <img src={themes[CurrentApp as keyof typeof themes].logo} alt="logo cannect" />
            </div>
          </a>
          <div className="logo-content box">
            <img src={themes[CurrentApp as keyof typeof themes].logoCannect} alt="logo cannect" />
          </div>
      </div>
      </Styles.Container>
      <Styles.BorderBottom />
    </>

  )
}

export default Menu
