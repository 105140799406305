import { ReactNode, useEffect, useState, useRef, FormEvent } from 'react'
import { Background, Container } from './styles'

type ModalProps = {
  children: ReactNode
  isOpened: boolean
  onRequestClose?: () => void
  className?: string
  style?: {
    width: string
  }
}

export const Modal = ({ children, isOpened, onRequestClose = () => null, className, style }: ModalProps) => {
  const backgroundRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(isOpened)
  }, [isOpened])

  function handleBackgroundClick(event: FormEvent) {
    if (event.target === backgroundRef.current) {
      onRequestClose()
    }
  }

  return isOpen ? (
    <Background onClick={event => handleBackgroundClick(event)} ref={backgroundRef}>
      <Container style={style} className={className}>
        {children}
      </Container>
    </Background>
  ) : null
}
