import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import AlertMessage from 'components/Alert'
import { OrderProps, PaymentMethod } from 'hooks/useOrderB2c'
import { BiBarcode } from 'react-icons/bi'
import * as Style from './styles'
import { BoletoBarcode } from './BoletoBarcode'

interface Props {
  order: OrderProps
  payment: PaymentMethod
  generateBoletoInfo: any
  barcodeInfo: BarcodeInfoProps
  generateBoleto: boolean
  setGenerateBoleto: any
  error: boolean
}

export interface BarcodeInfoProps {
  amount: number
  boleto_barcode: string
  boleto_expiration_date: Date
  boleto_url: string
}

export const Boleto = ({
  order,
  payment,
  generateBoletoInfo,
  barcodeInfo,
  generateBoleto,
  setGenerateBoleto,
  error,
}: Props) => {
  const { uuid } = useParams() as any

  const [messageAlert, setMessageAlert] = useState<boolean>(false)
  const defaultShippingValue = Number(order?.shipping) || 140

  const frete = defaultShippingValue - Number(order.shippingDiscount)

  const items = order?.items.reduce(
    (acc, item) => {
      const value = order?.coupon?.id ? item?.unitPrice : item?.imputed_value

      const price = value !== null && value !== undefined && Number(value) >= 0 ? Number(value) : Number(item?.unitPrice)

      acc.subtotal += price * item.quantity
      acc.total = acc.subtotal + frete - Number(acc.discount)

      return acc
    },
    {
      total: 0,
      subtotal: 0,
      discount: order?.discount,
      shippingDiscount: order?.shippingDiscount,
      productDiscount: 0,
    }
  )

  const handleBarcodeInfo = () => {
    generateBoletoInfo(uuid, payment)
    setGenerateBoleto(true)
  }

  const total = parseFloat(order?.amount ?? '0')

  useEffect(() => {
    if (error) {
      setMessageAlert(true)
    }
  }, [])

  return (
    <Style.Container>
      {messageAlert && <AlertMessage alertType="error" />}
      {!generateBoleto && !messageAlert && (
        <Style.BoletoRequest>
          <span>
            <BiBarcode size="5em" />
          </span>
          <h1>
            Valor a pagar:{' '}
            <b>
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol',
              }).format(total)}
            </b>
          </h1>
          <h2>Seu boleto ficará válido para pagamento por 5 dias úteis</h2>
          <Style.ButtonCopy onClick={handleBarcodeInfo}>Finalizar pedido e gerar boleto</Style.ButtonCopy>
          <h3>O prazo para compensação do pagamento é de até 3 dias úteis.</h3>
        </Style.BoletoRequest>
      )}
      {generateBoleto && <BoletoBarcode data={barcodeInfo} />}
    </Style.Container>
  )
}
