import styled from "styled-components";

export const Container = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  margin-top: 1rem;
  width: ${props => props.isMobile ? '100%' : '80%'};

  padding: 25.13px 27.45px 30.82px 33.45px;

  hr {
    border: 0.7px solid #777777;
    margin-top: 11px;
  }
`

export const Title = styled.h2`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  text-transform: uppercase;
`

export const Prices = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 16px;
    color: #777777;
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  .cupom-badge {
    color: #c5581bcc;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    text-align: right;
  }

  .cupom-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    span {
      display: flex;
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;

    .status-cupom {
      color: red;
      margin-bottom: -30px;
    }
  }

  span {
    text-align: right;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 16px;
    color: #777777;
  }
`

interface Coupon {
  isValid: boolean
}

export const CupomInput = styled.input<Coupon>`
  width: 187px;
  height: 35px;
  padding: 1rem;
  border: ${props => (props.isValid ? '1px solid rgba(197, 88, 27, 0.5)' : '')};
  margin-bottom: 0px;
`

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  .total-title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 17px;

    color: #000000;
  }

  .total {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: right;
  }
`

export const ButtonDiscount = styled.button`
  background:${props => props.theme.colors.primary};
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 35px;
  width: 70px;
  margin-left: 8px;
`
