import styled from 'styled-components'

export const Container = styled.div<any>`
  width: ${props => props.isMobile ? '100%': '80%'};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 20px;
  float: left;

  @media (max-width: 900px) {
    padding-right: 10px;
  }
`

export const InputContainer = styled.div<any>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  height: 40px;

  svg{
    width: 30px;
    height: 30px;
    padding-right: 10px;
  }

  input{
    border: none;
    padding-left: 1rem;
    width: ${props => props.isMobile ? '100%' : '250px'};
    background-color: white;

    ::placeholder {
      color: lightgray;
    }
  }
`
