import { useOrderB2c, PaymentMethod, OrderProps } from 'hooks/useOrderB2c'
import AlertMessage from 'components/Alert'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { formatCard, generateUniqueId, formatNewReal } from 'utils/formatters'
import { Select } from 'antd'
import 'react-loading-skeleton/dist/skeleton.css'
import * as Style from './styles'

const { Option } = Select

interface Props {
  order: OrderProps
  payment: PaymentMethod
}

const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
const years: number[] = []

export const CreditCart = ({order, payment}: Props) => {
  const { creditCard, changeCreditCardInfo, finishOrderCheckoutByType, error, setError, getInstallments, installments } = useOrderB2c()
  const history = useHistory()
  const {uuid} = useParams() as any

  function getYears() {
    const startYear = new Date().getFullYear()

    // eslint-disable-next-line no-plusplus
    for (let i = startYear; i <= 2050; i++) {
      years.push(i)
    }
  }

  async function finishOrder(){
    const response = await finishOrderCheckoutByType(uuid, payment)

    if (!error && response) {
      history.push(`/meu_pedido/${uuid}`)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if(order) {
      getInstallments(order?.id)
      changeCreditCardInfo('installments', '1')
    }
  }, [order])

  useEffect(() => {
    getYears()
  }, [])

  return (
      <Style.Container>
      {error && (
        <AlertMessage alertType="error" />
      )}
      {!error && (
        <>
          <Style.FormContainer>
            <div className="input-column">
              <span>Nome no cartão</span>
              <input
                type="text"
                id="card_name"
                required
                value={creditCard?.card_name}
                onChange={e => changeCreditCardInfo('card_name', e.target.value)}
              />

              <span>N° do cartão</span>
              <input
                type="text"
                id="card_number"
                required
                value={formatCard(creditCard?.card_number)}
                onChange={e => changeCreditCardInfo('card_number', e.target.value)}
              />
            </div>
            <div className="input-groups">
              <div>
                <span>Mes</span>
                <select
                  required
                  value={creditCard?.card_month_validate}
                  onChange={e => changeCreditCardInfo('card_month_validate', e.target.value)}
                >
                  {months.map(month => (
                    <option key={month}>{month}</option>
                  ))}
                </select>
              </div>
              <div>
                <span>Ano</span>
                <select
                  required
                  value={creditCard?.card_year_validate}
                  onChange={e => changeCreditCardInfo('card_year_validate', e.target.value)}
                >
                  {years.map(year => (
                    <option key={year}>{year.toString().substr(-2)}</option>
                  ))}
                </select>
              </div>
              <div>
                <span>CVV</span>
                <input
                  type="text"
                  id="card_cvv"
                  minLength={3}
                  maxLength={4}
                  required
                  value={creditCard?.card_cvv}
                  onChange={e => changeCreditCardInfo('card_cvv', e.target.value)}
                />
              </div>
            </div>
            <div className="input-column">
              <span>Parcelamento</span>
              <Select
                placeholder="Selecione a quantidade de parcelas."
                size="large"
                virtual={false}
                onChange={installment => changeCreditCardInfo('installments', installment)}
              >
                {installments?.map((i: any) => {
                  return (
                    <Option value={i?.id} key={generateUniqueId(i?.id)}>
                      <Style.OptionInstallment>
                        <p className="values_installment">
                          {`${i?.installment} de ${formatNewReal(i?.amount)}`}
                        </p>

                        {i?.fees === '(sem juros)' ? (
                          <p className="total_installment not_installment">Sem juros</p>
                        ) : (
                          <p className="total_installment">{formatNewReal(i?.total)}</p>
                        )}
                    </Style.OptionInstallment>
                    </Option>
                  )
                })}
              </Select>
            </div>
          </Style.FormContainer>
          <Style.ButtonContainer>
            <Style.BtnFinishOrder onClick={() => finishOrder()}>FINALIZAR PEDIDO</Style.BtnFinishOrder>
          </Style.ButtonContainer>
        </>
        )}
    </Style.Container>
  )
}
