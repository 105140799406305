import Loading from 'components/Loading'
import { useOrderB2c } from 'hooks/useOrderB2c'
import { getUserUuid } from 'hooks/useSessionUser'
import React, { useLayoutEffect, useState } from 'react'
import { AiOutlineDown, AiOutlineSearch, AiOutlineUpload } from 'react-icons/ai'
import { api } from 'services/api'
import {FaSpinner} from "react-icons/fa";
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

interface Document {
  id: number
  type: string
}

const fuList = [
  'Selecione',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
]

export const Documents = () => {

  const hiddenIdInput = React.useRef<null | any>(null)
  const hiddenAddressInput = React.useRef<null | any>(null)
  const hiddenRecipeInput = React.useRef<null | any>(null)
  const hiddenDependentInput = React.useRef<null | any>(null)
  const hiddenAnvisaInput = React.useRef<null | any>(null)
  const hiddenIdResponsable = React.useRef<null | any>(null)
  const hiddenProcuracao = React.useRef<null | any>(null)

  const [getIdDocument, setGetIdDocument] = useState<{ name: string }[]>([])
  const [getAddressDocument, setGetAddressDocument] = useState<{ name: string }[]>([])
  const [getRecipeDocument, setGetRecipe] = useState<{ name: string }[]>([])
  const [getDependentDocument, setGetDependentDocument] = useState<{ name: string }[]>([])
  const [getIdResponsableDocument, setGetIdResponsableDocument] = useState<{ name: string }[]>([])
  const [getProcuracaoDocument, setGetProcuracaoDocument] = useState<{ name: string }[]>([])
  const [getAnvisaDocument, setGetAnvisaDocument] = useState<{ name: string }[]>([])
  const [showDependentInput, setShowDependentInput] = useState(false)
  const [showDependentDocumentInput, setShowDependentDocumentInput] = useState(false)

  const [showFormPrescriber, setShowFormPrescriber] = useState(false)
  const [showFullnamePrescriberInput, setShowFullnamePrescriberInput] = useState(false)

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const {
    person,
    documents,
    loading,
    setLoading,
    setDocuments,
    recipesDocuments,
    setRecipeDocuments,
    setRecipeSend,
    prescriber,
    setPrescriber,
    changePrescriber,
    consultPrescriber,
    savePrescriber,
  } = useOrderB2c()

  const uuid = getUserUuid()

  const handleClick = (input: any) => {
    input?.current?.click()
  }

  const handleIdUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetIdDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }

    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=IDENTIDADE&emission=18%2F11%2F2022`,
        formData
      )
      const holdData = documents

      if (data) {
        console.log(data)

        setDocuments((currItems: Document[]) => {
          const hasIdentidade = currItems.some((item: Document) => item.type === 'IDENTIDADE');
          if (!hasIdentidade) {
            return [...currItems, { id: data.id, type: 'IDENTIDADE' }];
          }

          const updatedItems = currItems.map((item: Document) => {
            if (item.type === 'IDENTIDADE') {
              return { ...item, id: data.id };
            }
            return item;
          });

          return updatedItems;
        })
        console.log(documents)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleAddressUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetAddressDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=RESIDENCIAL&emission=18%2F11%2F2022`,
        formData
      )
      const holdData = documents

      if (data) {
        setDocuments((currItems: Document[]) => {
          const hasAddress = currItems.some((item: Document) => item.type === 'RESIDENCIAL');
          if (!hasAddress) {
            return [...currItems, { id: data.id, type: 'RESIDENCIAL' }];
          }

          const updatedItems = currItems.map((item: Document) => {
            if (item.type === 'RESIDENCIAL') {
              return { ...item, id: data.id };
            }
            return item;
          });

          return updatedItems;
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleRecipeUpload = async (event: any) => {
    setRecipeDocuments([])
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetRecipe(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=PRESCRICAO&emission=18%2F11%2F2022`,
        formData
      )
      const { data: recipeData } = await api.post('/order/send_recipe', formData)
      const holdData = recipesDocuments

      if (data) {
        setRecipeDocuments((currItems: any[]) => {
          const findeRecipe = currItems.find((item: Document) => item.type === 'PRESCRICAO')

          if (findeRecipe == null) {
            return [...currItems, { id: recipeData?.recipeSalved?.id, type: 'PRESCRICAO' }]
          }
          return currItems.map(item => {
            if (item.id === recipeData?.recipeSalved?.id) {
              return { ...item, id: recipeData?.recipeSalved?.id }
            }
            return item
          })
        })
        console.log(recipesDocuments);

        setRecipeSend(true)
      }

      console.log(documents)
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleDependentUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetDependentDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=VINCULO&emission=18%2F11%2F2022`,
        formData
      )
      // const holdData = documents

      if (data) {
        console.log(data)

        setDocuments((currItems: Document[]) => {
          if (currItems.find((item: Document) => item.type === 'VINCULO') == null) {
            return [...currItems, { id: data?.id, type: 'VINCULO' }]
          }
          return currItems
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleAnvisaUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetAnvisaDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=ANVISA&emission=18%2F11%2F2022`,
        formData
      )
      const holdData = documents

      if (data) {
        setDocuments((currItems: Document[]) => {
          const hasAnvisa = currItems.some((item: Document) => item.type === 'ANVISA');
          if (!hasAnvisa) {
            return [...currItems, { id: data.id, type: 'ANVISA' }];
          }

          const updatedItems = currItems.map((item: Document) => {
            if (item.type === 'ANVISA') {
              return { ...item, id: data.id };
            }
            return item;
          });

          return updatedItems;
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleIdResponsableUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetIdResponsableDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=IDENTIDADE_RESPONSAVEL&emission=18%2F11%2F2022`,
        formData
      )
      const holdData = documents

      if (data) {
        setDocuments((currItems: Document[]) => {
          const hasIdResponsable = currItems.some((item: Document) => item.type === 'IDENTIDADE_RESPONSAVEL');
          if (!hasIdResponsable) {
            return [...currItems, { id: data.id, type: 'IDENTIDADE_RESPONSAVEL' }];
          }

          const updatedItems = currItems.map((item: Document) => {
            if (item.type === 'IDENTIDADE_RESPONSAVEL') {
              return { ...item, id: data.id };
            }
            return item;
          });

          return updatedItems;
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleProcuracaoUpload = async (event: any) => {
    const { files } = event.target
    const data = files as unknown as { name: string }[]

    setGetProcuracaoDocument(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }
    try {
      setLoading(true)
      const { data } = await api.post(
        `/order_b2c/upload_documents/${person?.id}?type=PROCURACAO&emission=18%2F11%2F2022`,
        formData
      )
      const holdData = documents

      if (data) {
        setDocuments((currItems: Document[]) => {
          const hasProcucarao = currItems.some((item: Document) => item.type === 'PROCURACAO');
          if (!hasProcucarao) {
            return [...currItems, { id: data.id, type: 'PROCURACAO' }];
          }

          const updatedItems = currItems.map((item: Document) => {
            if (item.type === 'PROCURACAO') {
              return { ...item, id: data.id };
            }
            return item;
          });

          return updatedItems;
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const getUserDocuments = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/my_account/${uuid}`)

      data?.personalInformation?.documents.map((document: any) => {
        if (document?.type === 'IDENTIDADE') {
          const name = document?.url.split('IDENTIDADE-')[1]

          setGetIdDocument([{ name }])
          setDocuments((currItems: any[]) => {
            if (!currItems.find((item: Document) => item.type === document?.type)) {
              return [...currItems, { id: document?.id, type: document?.type }]
            }
            return currItems.map((item: Document) => {
              if (item.type === document.type) {
                return { id: document.id, type: document.type }
              }
              return item
            })
          })
        }
        if (document?.type === 'RESIDENCIAL') {
          const name = document?.url.split('RESIDENCIAL-')[1]

          setGetAddressDocument([{ name }])
          setDocuments((currItems: Document[]) => {
            if (!currItems.find((item: Document) => item.type === document?.type)) {
              return [...currItems, { id: document?.id, type: document?.type }]
            }
            return currItems.map((item: Document) => {
              if (item.type === document.type) {
                return { id: document.id, type: document.type }
              }
              return item
            })
          })
        }
        if (document?.type === 'VINCULO') {
          const name = document?.url.split('VINCULO-')[1]

          setGetDependentDocument([{ name }])

          setDocuments((currItems: Document[]) => {
            console.log(currItems)

            if (!currItems.find((item: Document) => item.type === document?.type)) {
              return [...currItems, { id: document?.id, type: document?.type }]
            }
            return currItems.map((item: Document) => {
              if (item.type === document.type) {
                return { id: document.id, type: document.type }
              }
              return item
            })
          })
        }
        return document
      })

      return data
    } catch (error) {
      setLoading(false)
      return error
    } finally {
      setLoading(false)
    }
  }

  const handleConsultPrescriber = async () => {
    setPrescriber({
      ...prescriber,
      name: '',
    })
    consultPrescriber(prescriber)
    setShowFullnamePrescriberInput(true)
  }

  const handleSavePrescriber = async () => {
    await savePrescriber()
    setShowFormPrescriber(false)
  }

  useLayoutEffect(() => {
    if(!prescriber.nr_council || !prescriber.council || !prescriber.uf_council ) {
      setShowFullnamePrescriberInput(false)
    }
    if(prescriber.id) {
      setShowFormPrescriber(false)
    }
  }, [prescriber])

  return (
    <Style.Container isMobile={isMobile}>
      <h1>Inserir documentos do <span>PACIENTE</span></h1>
      <Loading loading={loading} />
      <div className="horizontal-uploads">
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload>Receita médica</Style.TitleUpload>
            <Style.NameFile>{getRecipeDocument[0]?.name}</Style.NameFile>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenRecipeInput)}>
              {getRecipeDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenRecipeInput)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenRecipeInput} style={{ display: 'none' }} onChange={handleRecipeUpload} />
        </Style.UploadWrapper>
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>Documento de identidade (RG ou CPF)</Style.TitleUpload>
            <Style.NameFile>{getIdDocument[0]?.name}</Style.NameFile>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenIdInput)}>
              {getIdDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenIdInput)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenIdInput} style={{ display: 'none' }} onChange={handleIdUpload} />
        </Style.UploadWrapper>
      </div>
      <div className="horizontal-uploads">
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>Comprovante de residência</Style.TitleUpload>
            <Style.NameFile>{getAddressDocument[0]?.name}</Style.NameFile>
            <Style.CheckboxContainer>
              <input type="checkbox" onChange={e => setShowDependentInput(!showDependentInput)} />
              <span>O comprovante enviado está em nome de outra pessoa</span>
            </Style.CheckboxContainer>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenAddressInput)}>
              {getAddressDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenAddressInput)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenAddressInput} style={{ display: 'none' }} onChange={handleAddressUpload} />
        </Style.UploadWrapper>
        {showDependentInput || showDependentDocumentInput ? (
          <Style.UploadWrapper isMobile={isMobile}>
            <div className="upload-content">
              <Style.TitleUpload isMobile={isMobile}>Comprovante de vínculo (titular do comprovante)</Style.TitleUpload>
              <Style.NameFile>{getDependentDocument[0]?.name}</Style.NameFile>
            </div>
            {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenDependentInput)}>
              {getDependentDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenDependentInput)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
            <input
              type="file"
              ref={hiddenDependentInput}
              style={{ display: 'none' }}
              onChange={handleDependentUpload}
            />
          </Style.UploadWrapper>
        ) : null}
        {!showDependentInput && <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>Atribuir médico / profissional da saúde*</Style.TitleUpload>
            {prescriber.id && <Style.NameFile>{prescriber?.name}</Style.NameFile>}
          </div>
          <Style.ShowFormPrescriber onClick={() => setShowFormPrescriber(!showFormPrescriber)}>
            <AiOutlineDown />
          </Style.ShowFormPrescriber>
          <input type="file" ref={hiddenAddressInput} style={{ display: 'none' }} onChange={handleAddressUpload} />
        </Style.UploadWrapper>}
        {!!showFormPrescriber && isMobile && !showDependentInput &&(
          <Style.AddPrescriber isMobile={isMobile}>
            <Style.PrescriberForm isMobile={isMobile}>
              <Style.RowForm isMobile={isMobile}>
                <Style.InputField>
                  <span>Conselho</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.council}
                    onChange={e => changePrescriber('council', e.target.value)}
                  >
                    <option>--</option>
                    <option>CRM</option>
                    <option>CRO</option>
                    <option>CRMV</option>
                    <option>CREFITO</option>
                  </select>
                </Style.InputField>
                <Style.InputField>
                  <span>CRM/CRO/CRMV/CREFITO</span>
                  <input
                    type="text"
                    disabled={!!prescriber.id}
                    value={prescriber?.nr_council}
                    onChange={e => changePrescriber('nr_council', e.target.value)}
                  />
                </Style.InputField>
                <Style.InputField>
                  <span>UF</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.uf_council}
                    onChange={e => changePrescriber('uf_council', e.target.value)}
                  >
                    {fuList.map(uf => (
                      <option key={uf}>{uf}</option>
                    ))}
                  </select>
                </Style.InputField>
                <Style.SearchPrescriberButton disabled={!!prescriber.id} onClick={() => handleConsultPrescriber()}>
                  {loading ? <FaSpinner /> : (
                    <>
                      <AiOutlineSearch />
                    </>
                    )
                  }
                </Style.SearchPrescriberButton>
              </Style.RowForm>
              {showFullnamePrescriberInput && prescriber.nr_council && prescriber.council && prescriber.uf_council && (
                <Style.RowForm>
                  <Style.InputField>
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      id="name"
                      value={prescriber?.name}
                      disabled={!!prescriber.id}
                      onChange={e => changePrescriber('name', e.target.value)}
                    />
                  </Style.InputField>
                  <Style.SavePrescriberButton onClick={() => handleSavePrescriber()} disabled={!!prescriber?.id || !prescriber?.name}>
                    SALVAR
                  </Style.SavePrescriberButton>
                </Style.RowForm>
              )}
            </Style.PrescriberForm>
          </Style.AddPrescriber>
        )}
      </div>
      <div className="horizontal-uploads">
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload>Autorização ANVISA</Style.TitleUpload>
            <Style.NameFile>{getAnvisaDocument[0]?.name}</Style.NameFile>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenAnvisaInput)}>
              {getAnvisaDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenAnvisaInput)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenAnvisaInput} style={{ display: 'none' }} onChange={handleAnvisaUpload} />
        </Style.UploadWrapper>
        {!!showDependentInput && <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>Atribuir médico / profissional da saúde*</Style.TitleUpload>
            {prescriber.id && <Style.NameFile>{prescriber?.name}</Style.NameFile>}
          </div>
          <Style.ShowFormPrescriber onClick={() => setShowFormPrescriber(!showFormPrescriber)}>
            <AiOutlineDown />
          </Style.ShowFormPrescriber>
          <input type="file" ref={hiddenAddressInput} style={{ display: 'none' }} onChange={handleAddressUpload} />
        </Style.UploadWrapper>}
        {!!showFormPrescriber && !isMobile && !showDependentInput && (
          <Style.AddPrescriber>
            <Style.PrescriberForm>
              <Style.RowForm>
                <Style.InputField>
                  <span>Conselho</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.council}
                    onChange={e => changePrescriber('council', e.target.value)}
                  >
                    <option>--</option>
                    <option>CRM</option>
                    <option>CRO</option>
                    <option>CRMV</option>
                    <option>CREFITO</option>
                  </select>
                </Style.InputField>
                <Style.InputField>
                  <span>CRM/CRO/CRMV/CREFITO</span>
                  <input
                    type="text"
                    disabled={!!prescriber.id}
                    value={prescriber?.nr_council}
                    onChange={e => changePrescriber('nr_council', e.target.value)}
                  />
                </Style.InputField>
                <Style.InputField>
                  <span>UF</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.uf_council}
                    onChange={e => changePrescriber('uf_council', e.target.value)}
                  >
                    {fuList.map(uf => (
                      <option key={uf}>{uf}</option>
                    ))}
                  </select>
                </Style.InputField>
                <Style.SearchPrescriberButton disabled={!!prescriber.id} onClick={handleConsultPrescriber}>
                  {loading ? <FaSpinner /> : (
                    <>
                      <AiOutlineSearch />
                      PESQUISAR
                    </>
                    )
                  }
                </Style.SearchPrescriberButton>
              </Style.RowForm>
              {showFullnamePrescriberInput && prescriber.nr_council && prescriber.council && prescriber.uf_council && (
                <Style.RowForm>
                  <Style.InputField>
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      id="name"
                      value={prescriber?.name}
                      disabled={!!prescriber.id}
                      onChange={e => changePrescriber('name', e.target.value)}
                    />
                  </Style.InputField>
                  <Style.SavePrescriberButton onClick={() => handleSavePrescriber()} disabled={!!prescriber?.id || !prescriber?.name}>
                    SALVAR
                  </Style.SavePrescriberButton>
                </Style.RowForm>
              )}
            </Style.PrescriberForm>
          </Style.AddPrescriber>
        )}
        {!isMobile && !showFormPrescriber && !showDependentInput &&<Style.UploadWrapper style={{backgroundColor: 'transparent'}}/>}
      </div>
      {!!showFormPrescriber && !isMobile && !!showDependentInput &&(
      <div className="horizontal-uploads">
        <Style.UploadWrapper style={{backgroundColor: 'transparent'}}/>
          <Style.AddPrescriber>
            <Style.PrescriberForm>
              <Style.RowForm>
                <Style.InputField>
                  <span>Conselho</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.council}
                    onChange={e => changePrescriber('council', e.target.value)}
                  >
                    <option>--</option>
                    <option>CRM</option>
                    <option>CRO</option>
                    <option>CRMV</option>
                    <option>CREFITO</option>
                  </select>
                </Style.InputField>
                <Style.InputField>
                  <span>CRM/CRO/CRMV/CREFITO</span>
                  <input
                    type="text"
                    disabled={!!prescriber.id}
                    value={prescriber?.nr_council}
                    onChange={e => changePrescriber('nr_council', e.target.value)}
                  />
                </Style.InputField>
                <Style.InputField>
                  <span>UF</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.uf_council}
                    onChange={e => changePrescriber('uf_council', e.target.value)}
                  >
                    {fuList.map(uf => (
                      <option key={uf}>{uf}</option>
                    ))}
                  </select>
                </Style.InputField>
                <Style.SearchPrescriberButton disabled={!!prescriber.id} onClick={() => handleConsultPrescriber()}>
                  {loading ? <FaSpinner /> : (
                    <>
                      <AiOutlineSearch />
                      PESQUISAR
                    </>
                    )
                  }
                </Style.SearchPrescriberButton>
              </Style.RowForm>
              {showFullnamePrescriberInput && prescriber.nr_council && prescriber.council && prescriber.uf_council && (
                <Style.RowForm>
                  <Style.InputField>
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      id="name"
                      value={prescriber?.name}
                      disabled={!!prescriber.id}
                      onChange={e => changePrescriber('name', e.target.value)}
                    />
                  </Style.InputField>
                  <Style.SavePrescriberButton onClick={() => handleSavePrescriber()} disabled={!!prescriber?.id || !prescriber?.name}>
                    SALVAR
                  </Style.SavePrescriberButton>
                </Style.RowForm>
              )}
            </Style.PrescriberForm>
          </Style.AddPrescriber>
      </div>)}
      {!!showFormPrescriber && isMobile && showDependentInput && (
          <Style.AddPrescriber>
            <Style.PrescriberForm>
              <Style.RowForm>
                <Style.InputField>
                  <span>Conselho</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.council}
                    onChange={e => changePrescriber('council', e.target.value)}
                  >
                    <option>--</option>
                    <option>CRM</option>
                    <option>CRO</option>
                    <option>CRMV</option>
                    <option>CREFITO</option>
                  </select>
                </Style.InputField>
                <Style.InputField>
                  <span>CRM/CRO/CRMV/CREFITO</span>
                  <input
                    type="text"
                    disabled={!!prescriber.id}
                    value={prescriber?.nr_council}
                    onChange={e => changePrescriber('nr_council', e.target.value)}
                  />
                </Style.InputField>
                <Style.InputField>
                  <span>UF</span>
                  <select
                    disabled={!!prescriber.id}
                    value={prescriber.uf_council}
                    onChange={e => changePrescriber('uf_council', e.target.value)}
                  >
                    {fuList.map(uf => (
                      <option key={uf}>{uf}</option>
                    ))}
                  </select>
                </Style.InputField>
                <Style.SearchPrescriberButton disabled={!!prescriber.id} onClick={() => handleConsultPrescriber()}>
                  {loading ? <FaSpinner /> : (
                    <>
                      <AiOutlineSearch />
                      PESQUISAR
                    </>
                    )
                  }
                </Style.SearchPrescriberButton>
              </Style.RowForm>
              {showFullnamePrescriberInput && prescriber.nr_council && prescriber.council && prescriber.uf_council && (
                <Style.RowForm>
                  <Style.InputField>
                    <span>Nome Completo</span>
                    <input
                      type="text"
                      id="name"
                      value={prescriber?.name}
                      disabled={!!prescriber.id}
                      onChange={e => changePrescriber('name', e.target.value)}
                    />
                  </Style.InputField>
                  <Style.SavePrescriberButton onClick={() => handleSavePrescriber()} disabled={!!prescriber?.id || !prescriber?.name}>
                    SALVAR
                  </Style.SavePrescriberButton>
                </Style.RowForm>
              )}
            </Style.PrescriberForm>
          </Style.AddPrescriber>
        )}
      <h1>Inserir documentos do <span>RESPONSÁVEL (opcional)</span></h1>
      <div className="horizontal-uploads">
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>RG, CPF, CNH</Style.TitleUpload>
            <Style.NameFile>Nos envie a foto da frente e do verso </Style.NameFile>
            <Style.NameFile>{getIdResponsableDocument[0]?.name}</Style.NameFile>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenIdResponsable)}>
              {getIdResponsableDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenIdResponsable)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenIdResponsable} style={{ display: 'none' }} onChange={handleIdResponsableUpload} />
        </Style.UploadWrapper>
        <Style.UploadWrapper isMobile={isMobile}>
          <div className="upload-content">
            <Style.TitleUpload isMobile={isMobile}>Procuração em Nome do Responsável</Style.TitleUpload>
            <Style.NameFile>{getProcuracaoDocument[0]?.name}</Style.NameFile>
          </div>
          {!isMobile ? (
            <Style.ButtonUpload onClick={() => handleClick(hiddenProcuracao)}>
              {getProcuracaoDocument.length > 0 ? 'substituir' : 'upload'}
              <AiOutlineUpload />
            </Style.ButtonUpload>
            ) : (
            <Style.ButtonUpload onClick={() => handleClick(hiddenProcuracao)} isMobile={isMobile}>
              <AiOutlineUpload />
            </Style.ButtonUpload>
            )}
          <input type="file" ref={hiddenProcuracao} style={{ display: 'none' }} onChange={handleProcuracaoUpload} />
        </Style.UploadWrapper>
      </div>
    </Style.Container>
  )
}
