import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`
export const ArrowSteps = styled.div<any>`
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
`

export const Step = styled.div<any>`
  text-align: center;
  color: ${props => (props.current ? '#fff' : '#000')};
  background-color: ${props => (props.current ? props.theme.colors.primary : '#D1CECE;')};
  cursor: default;
  margin: 0 3px;
  margin-top: ${props => (props.isMobile ? '10px' : '')};
  width: 255.1px;
  padding: 15px 10px 15px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  ${props =>
  props.current &&
  `
      color: #fff;
      background-color: ${props.theme.colors.primary};
  `}

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 25.5px solid transparent;
    border-left: 17px solid #d1cece;
    z-index: 2;
    transition: border-color 0.2s ease;
  }

  ${props =>
  !props.isMobile &&
  `
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      right: -17px;
      width: 0;
      height: 0;
      border-top: 19px solid transparent;
      border-bottom: 25.5px solid transparent;
      border-left: 17px solid #d1cece;
      z-index: 2;
      transition: border-color 0.2s ease;

      right: auto;
      left: 0;
      border-left: 17px solid #fff;
      z-index: 0;
    }
  `}

  &:first-child:before {
    border: none;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  span {
    position: relative;
    font-family: 'Lato';
    font-weight: 600;
  }

  span:before {
    opacity: 0;
    position: absolute;
    top: -2px;
    left: -20px;
  }

  span:before {
    ${props =>
  props.current &&
  `
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  `}
  }

  &:after {
    ${props =>
  props.current &&
  `
      border-left: 17px solid ${props.theme.colors.primary};
  `}
  }
`
