import styled from "styled-components";

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  width: ${props => props.isMobile ? '100%' : '60%'};
`
export const Box = styled.div<any>`
  background: #F6F6F6;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
`

export const Texts = styled.div<any>`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: ${props => props.isMobile ? '100%' : ''};
  justify-content: center;
  h2{
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 8px;
    margin-bottom: 0.6rem;
  }

  a{
    font-size: ${props => props.isMobile ? '0.8rem' : '0.9rem'};
    color: #4380E6;
    text-decoration: none;
    margin: ${props => props.isMobile ? '0.7rem 0' : ''};
  }
`

export const CopyButton = styled.button<any>`
  border: none;
  background-color: ${props => props.theme.colors.primary};
  width:  ${props => props.isMobile ? '100%' : '150px'};
  height: ${props => props.isMobile ? '50%' : ''};
  align-items: center;
  font-variant: small-caps;

  color: #FFFDF9;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  cursor: pointer;
`

export const FinishedButton = styled.button`
  background: #3D3D3D;
  border-radius: 3px;
  margin-top: 1rem;
  border: none;
  padding: 1rem 3rem;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;

  &:disabled{
    opacity: 0.7;
    cursor: not-allowed;
  }
`
export const BoxPdfDownload = styled.button`
  all: unset;
  background: rgba(217, 217, 217, 0.5);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0.8rem 1.2rem;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  span{
    font-weight: 500;
  }

  svg{
    margin-top: -4px;
    margin-left: 0.7rem;
  }
`
