import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { MOBILE_WIDTH } from "utils/constants"
import Pagination from "components/Pagination"
import { Accordion } from "../Accordion"
import { RowTable } from "../RowTable"
import * as Style from "./styles"


export const NewTable = () => {
  const {filteredOrders,getMyOrders, countAndTotalAmountsOrders, setPageOrders, filteredList, pageOrders, forcePage, setForcePage} = useOrderB2c()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const handleNextPage = (selected: any = 1) => {
    let page
    
    if (selected === 1) {
      page = 0
    }else {
      page = `${(selected -  1)}0`
    }

    setPageOrders(page)

    const payload = {
      limit: 10,
      page
    }

    getMyOrders(payload)
  }

  return (
      <Style.Container isMobile={isMobile}>
        {!isMobile && <Style.Header isMobile={isMobile}>
          <div className="header-title">
            <span>NÚMERO DO <br />PEDIDO</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-20px'}}>STATUS DO <br />ATENDIMENTO</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-180px'}}>STATUS <br />PAGAMENTO</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-200px'}}>DATA <br />DO ATENDIMENTO</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-160px'}}>PESSOA</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-150px'}}>VALOR</span>
          </div>
          <div className="header-title">
            <span style={{marginRight: '-50px'}}>DETALHES</span>
          </div>
        </Style.Header>}
        {!isMobile ? filteredOrders?.map(order => (
          <RowTable order={order} key={order?.id}/>
        )) : (
          filteredOrders?.map(order => (
            <Accordion order={order} key={order?.id}/>
          ))
        )}
        <Style.ContentPagination>
          <Pagination
            forcePage={forcePage}
            onPageChange={({ selected }: any) => handleNextPage(selected + 1)}
            pageCount={!filteredList ? Math. ceil(Number(countAndTotalAmountsOrders?.count) / 10) : Math. ceil(Number(countAndTotalAmountsOrders?.count) / 10)}
          />
        </Style.ContentPagination>
      </Style.Container>
  )
}
