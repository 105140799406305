import { useState } from 'react';
import Barcode from 'react-barcode';
import { formatIsoDateToDisplay } from 'utils/formatters'
import { BiCopy } from 'react-icons/bi'
import * as Style from '../styles'

export interface BarcodeInfoProps {
  data: {
    amount: number
    boleto_barcode: string
    boleto_expiration_date: Date
    boleto_url: string
  }
}

const largura = window. screen. width;

export const BoletoBarcode = ({ data }: BarcodeInfoProps) => {
  const [lineIsCopied, setLineIsCopied] = useState<boolean>(false);
  const [, setCopyClipboard] = useState<string>('')

  const copyByClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopyClipboard(text)
    setLineIsCopied(true)
  }

    return (
    <Style.DataBoleto>
      <p>
        Para pagar pelo Internet Banking, copie a linha digitável ou escaneie o código de barras.
        Para pagar em qualquer banco, caixa eletrônico ou lotérica, por favor, imprima o boleto.
      </p>
      <Barcode
        value={data.boleto_barcode}
        displayValue={false}
        background="#f6f6f6"
        width={largura > 1480 ? 1 : .6}
        height={50}
      />
      {!lineIsCopied ? (
        <Style.CopyCode onClick={() => copyByClipboard(data.boleto_barcode)}>
          Copiar linha
          <BiCopy />
        </Style.CopyCode>
      ) : 
        <Style.CodeCopied >
          Linha copiada
          <BiCopy />
        </Style.CodeCopied>
      }
      <Style.DataFavored>
        <h1>Favorecido: <span>CANNECT LTDA | PAGAR.ME</span></h1>
        <h1>Valor do pagamento: <b>{new Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol'
          }).format(data.amount / 100)}</b>
        </h1>
        <h1>Vencimento: <span>{formatIsoDateToDisplay(data.boleto_expiration_date.toString())}</span></h1>
        <h2>
          O prazo para compensação do pagamento é de até 3 dias úteis.
          <br/>
          Para baixar o boleto, <Style.LinkBoleto href={data.boleto_url} download target="_blank">clique aqui</Style.LinkBoleto>
        </h2>
      </Style.DataFavored>
    </Style.DataBoleto>
  )
}
