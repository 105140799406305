const zIndex = {
  z1: 100,
  z2: 200,
  z3: 300,
  z4: 400,
  z5: 500,
  z6: 600,
  z7: 700,
  z8: 800,
  z9: 900,
}
export const spacing = {
  space1: '4px',
  space2: '8px',
  space3: '16px',
  space4: '24px',
  space5: '32px',
  space6: '40px',
  space7: '48px',
  space8: '56px',
  space9: '64px',
}
const shadow = {
  shad1: '0px 2px 5px 1px rgba(0, 0, 0, 0.05)',
}
const colors = {
  primary: '#12624D',
  primaryLight: '#199675',
  secondary: '#DC602E',
  tertiary: '#4380E6',
  pathway: '#D9D9D9',
  white: '#FFFFFF',
  black: '#000000',
  darkGray: '#555358',
  gray: '#929392',
  gold: '#E0AF00',
  special1: '#D2DDAE',
  special2: '#AFDDAE',
  special3: '#AEDDD2',
  special4: '#AEC6DD',
  special5: '#D9AEDD',
  special6: '#DDAEB9',
  special7: '#DDB9AE',
  special8: '#DDCDAE',
  base: '#5B945B',
  success: '#5B945B',
  red500: '#D71919',
  gray500: '#BBBEBD',
}
export const theme = {
  zIndex,
  colors,
  spacing,
  shadow,
  fontSize: {
    h1: '3rem',
    h2: '2.5rem',
    disable1: '6rem',
    disable2: '4rem',
    lg: '1.25rem',
    md: '1rem',
    sml: '0.8rem',
  },
}
