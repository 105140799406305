const USER = '@ADM_CANNECT_USER_CONFIG'

const getUserID = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).id
  }
  return null
}

const getUserUuid = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).uuid
  }
  return null
}

const getUserPermission = () => {
  const userStorage = localStorage.getItem(USER)
  if (userStorage) {
    return JSON.parse(userStorage).profile.permissions
  }
  return null
}

export {
  getUserID,
  getUserUuid,
  getUserPermission
}
