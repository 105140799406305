import { api } from 'services/api'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { getApp } from 'utils/sub-domains'
import { getItem } from 'utils/persistenceUtils'
import { normalizeName } from 'utils/formatters'
import { ItemOrder, Person, Prescriber, Status } from 'hooks/useOrderB2c'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH, USER_CONFIG } from 'utils/constants'
import Loading from 'components/Loading'
import Steps from './Steps'
import truck from '../../../assets/img/truck.png'
import * as Style from './styles'

export interface OrderProps {
  id?: number
  uuid?: string
  amount?: string
  attendant?: AttendantDate
  address_number?: string
  cep?: string
  city?: string
  complement?: string
  coupon?: Coupon
  state?: string
  district?: string
  createdAt: Date
  status?: Status
  person?: Person
  prescriber?: Prescriber
  shipping?: string
  discount?: string
  shippingDiscount?: string
  items: ItemOrder[]
}

interface Coupon {
  type?: string
  type_shipping?: string
  value?: string
  value_shipping?: string
  name?: string
  id?: number
}

interface AttendantDate {
  name: string
}

const OrderStatusAttendant = () => {
  const { uuid } = useParams() as any
  const brand = getApp()
  const [loading, setLoading] = useState(false)

  const [user, setUser] = useState<any>('')
  const [order, setOrder] = useState<OrderProps>({
    createdAt: new Date(),
    items: [],
  })

  const history = useHistory()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const defaultShippingValue = Number(order?.shipping) || 140
  const frete = defaultShippingValue - Number(order.shippingDiscount)

  const link = `https://${brand}.cannect.life/checkout/${order?.uuid}`

  const [text, setText] = useState('COPIAR')
  async function copyUrl() {
    navigator.clipboard.writeText(link ?? '')
    setText('COPIADO!')
  }

  const items = order?.items.reduce(
    (acc, item) => {
      const value = order?.coupon?.id ? item?.unitPrice : item?.imputed_value
      const price = value !== null && value !== undefined && Number(value) >= 0 ? Number(value) : Number(item?.unitPrice)

      acc.subtotal += price * item.quantity
      acc.total = acc.subtotal + frete - Number(acc.discount)

      return acc
    },
    {
      total: 0,
      subtotal: 0,
      discount: order?.discount,
      shippingDiscount: 0,
      productDiscount: 0,
    }
  )

  const getOrder = async () => {
    try {
      setLoading(true)
      const { data } = await api.get(`/order_b2b_status/${uuid}`)
      console.log(data)

      setOrder(data.order)
      setLoading(false)
      return order
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  const handleBack = () => {
    history.push(`/pedidos`)
  }

  useEffect(() => {
    getOrder()
    const user: any = getItem(USER_CONFIG)
    setUser(JSON.parse(user))

    console.log(JSON.parse(user))
    console.log('order--->', order)
  }, [])

  return (
    <Style.Container isMobile={isMobile}>
      {loading && <Loading loading={loading} />}
      <Steps />
      <Style.Content isMobile={isMobile}>
        <Style.Top>
          <h1>Detalhes do pedido</h1>
          <Style.Back onClick={handleBack}>
            <ArrowLeftOutlined style={{ paddingRight: '5px' }} />
            Voltar
          </Style.Back>
        </Style.Top>
        <Style.OrderDetail>
          <p>
            Nome do paciente: <b>{normalizeName(order?.person?.name)}</b>
          </p>
          <p>
            Nome do consultor: <b>{normalizeName(order?.attendant?.name)}</b>
          </p>
          <p>
            Médico prescritor: <b>{normalizeName(order?.prescriber?.name)}</b>
          </p>
        </Style.OrderDetail>
        <h1>Resumo da compra</h1>
        <Style.HeaderContent isMobile={isMobile}>
          <div className="box white">
            <div className="box-content">
              <h2>Status de pagamento</h2>
              <span>{order?.status?.payment_status?.translated_name}</span>
              <span>Data do Pedido: {format(new Date(order.createdAt), 'dd/MM/yyyy')}</span>
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Método de pagamento</h2>
              <span>{order?.status?.order_status?.translated_name}</span>
              <span>Data do Pedido: {format(new Date(order.createdAt), 'dd/MM/yyyy')}</span>
            </div>
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Endereço de entrega</h2>
              <span>
                {order?.district}, {order?.address_number}
              </span>
              <span>
                {order?.city}, {order?.state}, {order?.cep}
              </span>
            </div>???
          </div>
          <div className="box">
            <div className="box-content">
              <h2>Alguma dúvida? Fale conosco:</h2>
              <span>Falar com atendente</span>
            </div>
          </div>
        </Style.HeaderContent>
        <Style.ProductsInfo isMobile={isMobile}>
          <Style.HeaderInfo isMobile={isMobile}>
            <div className="content-info">
              <p>Nº do pedido: {order?.id}</p>
            </div>
            <div className="content-transport">
              {/* <p>TRANSPORTADOR: FEDEX</p> */}
            </div>
            <div className="badge-status">
              <span>{order?.status?.order_status?.translated_name}</span>
            </div>
          </Style.HeaderInfo>
          <Style.ProductList>
            {!isMobile && (
              <Style.HeaderRow>
                <Style.HeaderColumn style={{ width: '65%' }}>PRODUTOS</Style.HeaderColumn>
                <Style.HeaderColumn>QUANT.</Style.HeaderColumn>
                <Style.HeaderColumn>VALOR UN.</Style.HeaderColumn>
              </Style.HeaderRow>
            )}
            {order.items?.map(item => (
              <Style.ProductRow>
                <Style.ProductName>
                  <img
                    src={
                      item?.product?.media.length > 0
                        ? item?.product?.media[0].path
                        : 'https://s3.amazonaws.com/cannect.portal.upload/produtos/14571/foto+1.jpg'
                    }
                    alt="Imagem produto"
                  />
                  <span>{item?.product?.translated_name}</span>
                </Style.ProductName>
                <Style.ProductQuantity>
                  <span>{item?.quantity}</span>
                </Style.ProductQuantity>
                <Style.ProductColumn>
                  <span>
                    {new Intl.NumberFormat('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                      currencyDisplay: 'symbol',
                    }).format(Number(order?.coupon?.id ? item.unitPrice : item?.imputed_value))}
                  </span>
                </Style.ProductColumn>
              </Style.ProductRow>
            ))}
          </Style.ProductList>
          <Style.Resume>
            <Style.ResumeTitles>
              <p>Subtotal</p>
              <p>Custo de importação</p>
              <p>Descontos</p>
              <span>Total</span>
            </Style.ResumeTitles>
            <Style.ResumeValues>
              <p>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(Number(items?.subtotal))}
              </p>
              <p>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(frete)}
              </p>
              <p>
                -{' '}
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(Number(items?.discount))}
              </p>
              <span>
                {new Intl.NumberFormat('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                }).format(Number(items?.total))}
              </span>
            </Style.ResumeValues>
          </Style.Resume>
        </Style.ProductsInfo>
      </Style.Content>
      <Style.ContainerLink>
        <Style.Box isMobile={isMobile}>
          <Style.Texts isMobile={isMobile}>
            <h2>URL DO ORÇAMENTO</h2>
            <a href={link}>{link}</a>
          </Style.Texts>
          <Style.CopyButton isMobile={isMobile} onClick={() => copyUrl()}>
            {text}
          </Style.CopyButton>
        </Style.Box>
      </Style.ContainerLink>
    </Style.Container>
  )
}

export default OrderStatusAttendant
