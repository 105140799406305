import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const PrivateRoute = ({ ...rest }: RouteProps) => {
  // const auth = getItem(TOKEN_KEY)
  // if (!auth) return <Redirect to="login" />
  return <Route {...rest} />
}

export default PrivateRoute
