import styled from 'styled-components'

export const LogoutContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	@media (max-width: 900px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const UserButton = styled('button')`
  background: ${(props) => props.theme.colors.primary};
  border: none;
	width: 35px;
	height: 35px;
	border-radius: 50px;
  color:#ffffff;
	cursor: pointer;
`

export const LogoutButton = styled.div`
	width: 100%;
	height: 100%;
	color: ${(props) => props.theme.colors.primary};
	cursor: pointer;
`

export const LogoutUser = styled.div`
	color: ${(props) => props.theme.colors.primary};
	font-size: 18px;
	margin-left: 10px;

	@media (max-width: 900px) {
		font-size: 14px;
	}
`