import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { formatZipCode } from 'utils/formatters'
import { useOrderB2c } from '../../../hooks/useOrderB2c'
import * as Style from './styles'

const fuList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]

export const DeliveryAddress = () => {
  const { shippingAddress, changeShippingAddress, setActiveBilling, activeBilling } = useOrderB2c()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>ENDEREÇO DE ENTREGA</Style.Title>
      <Style.FormContainer>
        <div className="input-column">
          <span>CEP</span>
          <input
            type="text"
            id="cep"
            disabled
            value={formatZipCode(shippingAddress?.cep)}
            onChange={event => changeShippingAddress('cep', event.target.value)}
          />

          <span>Endereço</span>
          <input
            type="text"
            id="street"
            disabled
            value={shippingAddress?.street}
            onChange={event => changeShippingAddress('street', event.target.value)}
          />
        </div>

        <div className="input-groups">
          <div>
            <span>N°</span>
            <input
              type="text"
              id="address_number"
              disabled
              value={shippingAddress?.address_number}
              onChange={event => changeShippingAddress('address_number', event.target.value)}
            />
          </div>
          <div>
            <span>Complemento</span>
            <input
              type="text"
              id="complement"
              disabled
              value={shippingAddress?.complement}
              onChange={event => changeShippingAddress('complement', event.target.value)}
            />
          </div>
        </div>

        <div className="input-groups">
          <div>
            <span>Cidade</span>
            <input
              type="text"
              id="city"
              disabled
              value={shippingAddress?.city}
              onChange={event => changeShippingAddress('city', event.target.value)}
            />
          </div>
          <div>
            <span>Estado</span>
            <select
              disabled
              required
              value={shippingAddress?.state}
              onChange={event => changeShippingAddress('state', event.target.value)}
            >
              <option>Selecione um estado</option>
              {fuList.map(fu => (
                <option key={fu}>{fu}</option>
              ))}
            </select>
          </div>
        </div>
      </Style.FormContainer>
      <Style.Title>ENDEREÇO DE COBRANÇA</Style.Title>
      <Style.CheckBoxContainer>
        <input type="checkbox" checked={activeBilling} onChange={() => setActiveBilling(!activeBilling)} />
        <span>Adicionar endereço diferente do endereço de entrega</span>
      </Style.CheckBoxContainer>
    </Style.Container>
  )
}
