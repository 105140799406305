import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { useState } from "react"
import { MOBILE_WIDTH } from "utils/constants"
import { ListProduct } from "./ListProducts"
import * as Style from "./styles"

export const SelectProducts = () => {
  const {filteredProducts, searchProducts} = useOrderB2c()
  const [search, setSearch] = useState()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.Container isMobile={isMobile}>
      <Style.Input
        type="text"
        placeholder="Pesquise por um produto"
        value={search}
        onChange={e => searchProducts(e.target.value)}
      />
      <ListProduct products={filteredProducts}/>
    </Style.Container>
  )
}
