import styled from "styled-components";

export const Container = styled.div<any>`
  margin-top:32px;
`

export const Title = styled.h1<any>`
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 6px;
`

export const Text = styled.p<any>`
  margin-bottom: 2rem;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.2;
`
