// import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { BiCart, BiFile, BiUserPin } from 'react-icons/bi'
import { RiMedicineBottleLine } from "react-icons/ri"
// import { MOBILE_WIDTH } from 'utils/constants'
import useMediaQuery from 'hooks/useMediaQuery';
import { MOBILE_WIDTH } from 'utils/constants';
import * as Style from './styles'
import {getApp} from "../../../../utils/sub-domains";
import {themes} from "../../../../styles/theme/brand-theme";

interface StepButtonProps {
  step: string
  active: boolean
  disabled?: boolean
  onClick?: () => void
}

export const StepButton = ({ step, active, disabled, onClick }: StepButtonProps) => {
  const [background, setBackground] = useState('#21976E')
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const CurrentApp = getApp()

  useEffect(() => {
    if (active) {
      setBackground(`${themes[CurrentApp as keyof typeof themes].colors.tertiary}`)
    } else if (!active) {
      setBackground(`#E3E3E3`)
    }

    if (disabled) {
      setBackground(`#fff`)
    }
  }, [active, disabled])

  return (
    <Style.StepButtonContainer
      style={disabled ? { background, color: 'black' } : { background, color: 'white' }}
      disabled={disabled}
      onClick={onClick}
      isMobile={isMobile}
    >
      {step === 'identification' && <BiUserPin size={25} />}
      {step === 'select-products' && <RiMedicineBottleLine size={25} />}
      {step === 'cart' && <BiCart size={25} />}
      {step === 'documents' && <BiFile size={25} />}
    </Style.StepButtonContainer>
  )
}
