import ContextProviders from 'contexts'
import React, {useEffect, useState} from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyles from 'styles/global'
import {ThemeProvider} from "styled-components";
import { Helmet } from "react-helmet";
import Routes from './routes'
import {themes} from "./styles/theme/brand-theme";
import {getApp, getTitle} from "./utils/sub-domains";

const App = () => {
  const CurrentApp = getApp()
  const title = getTitle()

  return (
    <>
      <Helmet>
          <link id="favicon" rel="icon" href={`/img/${CurrentApp}.png`} data-react-helmet="true" sizes='16x16'/>
          <link rel="apple-touch-icon" href={`/img/${CurrentApp}.png`} data-react-helmet="true" type="image/x-icon" sizes='16x16'/>
          <title>{title}</title>
          <meta name="description"content="Checkout para representantes"/>
      </Helmet>
      <ThemeProvider theme={themes[CurrentApp as keyof typeof  themes]}>
      <BrowserRouter>
        <ContextProviders>
          <GlobalStyles />
          <Routes />
        </ContextProviders>
      </BrowserRouter>
    </ThemeProvider>
    </>

  )
}

export default App
