import styled from "styled-components";

export const Container = styled.main`
  margin-top: 4rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* margin-bottom: 23rem; */

  .modal-error{
    padding: 0;
    margin: 0;
    width: 35%;
  }
`
export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;

  .steps-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
      color: #3D3D3D;
      font-size: 12px;
      font-weight: 600;
      margin-top: 0.8rem;
    }
  }
`
export const ModalContainer = styled.div`

`

export const ModalHeader = styled.div`
  background: #008AAD;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 0.7rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img{
    margin-top: 10px;
    margin-right: 20px;
  }

    font-weight: 600;
    font-size: 20px;
    align-items: center;
    text-align: center;

    color: #F3F3F3;

`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 149.51%;
  letter-spacing: 0.005em;

  span{
    color: #21976E;
    text-decoration: underline;
    cursor: pointer;
  }

  p{
    text-align: center;
    color: #646464;
  }

  button{
    border: none;
    background: transparent;

    text-decoration: underline;
    cursor: pointer;
    color: #555358;
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }

  .create-user{
    color: #555358;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1rem;
  }
`
