import styled from "styled-components";

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 1rem;
  margin-bottom: 10rem;
`
export const Content = styled.div<any>`
  width: ${props => props.isMobile ? '100%': '65%'};
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1{
    color: #000000;
    font-size: 1.5rem;
    margin-right: auto;
  }
`

export const HeaderContent = styled.div<any>`
  display: flex;
  flex-wrap: ${props => props.isMobile ? 'wrap': ''};
  width: 100%;
  margin-top: 1rem;

  .box{
    width: 100%;
    background: #F1F2FA;
    border-radius: 5px;

    .box-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1.5rem 2.2rem;
      gap: 10px;

      h2{
        color: #000000;
        font-size: 0.8rem;
      }

      span{
        font-size: 0.8rem;
        color:#777777;
      }
    }
  }

  .white{
    background-color: white
  }

`

export const ProductsInfo = styled.div<any>`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F1F2FA;
  border-radius: 5px;
  padding: 1.5rem;
`

export const HeaderInfo = styled.div<any>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  .content-info{
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: #000000;
    }
  }

  .content-transport{
    display: flex;
    align-items: center;

    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;

      color: #000000;
    }

    img{
      width: 30px;
      height: 30px;
      margin-right: 1rem;
    }
  }

  .badge-status{
    span{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;

      background: rgba(255, 0, 0, 0.5);
      color: white;
      padding: 0.7rem;
      border-radius: 50px;
    }
  }
`

export const ProductList = styled.div`
  background-color: white;
  width: 95%;
  margin-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`

export const ProductRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`

export const HeaderColumn = styled.div`
  color: #000000;
  font-weight: 300;
  font-size: 15.3331px;
  line-height: 18px;
`

export const ProductColumn = styled.div`
  display: flex;
  align-items: center;
  span{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
  }
  img{
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
`
export const ProductQuantity = styled.div`
  display: flex;
  align-items: center;
  span{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
  }
  img{
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
`

export const ProductName = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  span{
    color: #000000;
    font-size: 14px;
    font-weight: 400;
  }
  img{
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
`

export const Resume = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #D1CECE;
`

export const ResumeTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  p{
    color: #777777;
    font-size: 14px;
  }

  span{
    margin-top: 10px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
`


export const ResumeValues = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.6rem;

  p{
    color: #777777;
    font-size: 14px;
  }

  span{
    margin-top: 10px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
  }
`

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 65%;
`
export const Box = styled.div`
  background: #F1F2FA;
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Texts = styled.div`
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2{
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 8px;
    margin-bottom: 0.6rem;
  }

  a{
    color: #4380E6;
    text-decoration: none;
  }
`

export const CopyButton = styled.button`
  border: none;
  background-color: ${props => props.theme.colors.primary};;
  width: 150px;
  align-items: center;
  font-variant: small-caps;
  height: 100%;

  color: #FFFDF9;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  cursor: pointer;
`

export const FinishedButton = styled.button`
  background: #3D3D3D;
  border-radius: 3px;
  margin-top: 1rem;
  border: none;
  padding: 1rem 3rem;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;

  &:disabled{
    opacity: 0.7;
    cursor: not-allowed;
  }
`
