import { theme } from 'design-cannect'
import { useContext, useEffect, useState } from 'react'
import * as Style from './styles'
// import { useCartCheckout } from '../../../../hooks/useCartCheckout'

interface ConnectorProps {
  active: boolean
  disabled: boolean
}

export const Connector = ({ active, disabled }: ConnectorProps) => {
  const [background, setBackground] = useState('#8E8D8D')

  useEffect(() => {
    if (active) {
      setBackground(`#8E8D8D`)
    } else if (!active) {
      setBackground(`#8E8D8D`)
    }

    if (disabled) {
      setBackground(`#8E8D8D`)
    }
  }, [active, disabled])

  return <Style.ConnectorContainer style={{ background }} />
}
