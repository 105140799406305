import { Alert, Space } from 'antd';

interface Props {
    alertType: string
    alertMessage?: string
    alertDescription?: string
}

const AlertMessage = ({ alertType, alertMessage, alertDescription }: Props) => {
 
    return (
    <Space direction="vertical" style={{ width: '100%' }}>
    { alertType === 'success' && (
    <Alert
    message={alertMessage || "Operação realizada com sucesso"}
    description={alertDescription || "Sua operação foi realizada com sucesso."}
    type="success"
    showIcon
  />
    )}
    { alertType === 'info' && (
        <Alert
        message={alertMessage}
        description={alertDescription}
        type="info"
        showIcon
      />
    )}
    { alertType === 'warning' && (
        <Alert
        message={alertMessage}
        description={alertDescription}
        type="warning"
        showIcon
        closable
      />
    )}
    { alertType === 'error' && (
       <Alert
       message={alertMessage || "Erro ao carregar dados"}
       description={alertDescription || "Tente atualizar a página e realizar a operação novamente. Se o problema persistir entre em contato com nosso suporte."}
       type="error"
       showIcon
     />
    )}
  </Space>
)};

export default AlertMessage;