import React, { useState } from 'react'
import { IChildTypes } from 'types/childrenTypes'
import Menu from '../Menu'
import Footer from '../Footer'
import * as S from './styles'

const Layout = ({ children }: IChildTypes) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen)
  }

  return (
    <div className="bg-backgroundLight">
      <div className={`flex flex-1 flex-col duration-300 ${sidebarOpen ? 'md:pl-56' : 'md:pl-20'} `}>
        <Menu />
        <S.LayoutContainer>{children}</S.LayoutContainer>
        <Footer />
      </div>
    </div>
  )
}

export default Layout
