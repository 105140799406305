import { useOrderB2c } from "hooks/useOrderB2c";
import { formatCpf } from "utils/formatters";
import { enterIcon } from "assets/svg"
import { FormEvent, useEffect, useState } from "react"
import { getUserPermission } from "hooks/useSessionUser";
import useMediaQuery from "hooks/useMediaQuery";
import { useHistory } from "react-router-dom";
import { MOBILE_WIDTH, routes } from "utils/constants";

import { getApp } from "utils/sub-domains";
import * as Styles from "./styles"

import 'animate.css';

export const SearchUser = () => {

  const {changePerson, setActiveForm, setPerson, getRepresentants, representants, changeRepresentant, representant} = useOrderB2c()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const brandName = getApp()

  const [cpfValue, setCPfValue] = useState<string>('')

  const permissions = getUserPermission()
  const isAdmin = permissions.find((permission: { role: string; }) =>  permission.role === `${brandName}-admin`)

  const fetchSearch = async (e: FormEvent) => {
    e.preventDefault()
    changePerson('cpf', cpfValue, 'searchCPF')
  }

  const crateUser = async () => {
    setActiveForm('data-user')
    setPerson({})
  }

  const disabledInputAdmin = cpfValue.replace(/\D/g, '').length < 11 || representant === null || !representant
  const disabledInputRepresentant = cpfValue.replace(/\D/g, '').length < 11

  const disableInput = isAdmin ? disabledInputAdmin : disabledInputRepresentant

  const backToOrders = () => {
    history.push(routes.PEDIDOS)
  }

  const handleCpfValue = (value: string) => {
    setCPfValue(value)
  }

  useEffect(() => {
    if(isAdmin){
      getRepresentants()
    }
  }, [])

  return (
        <>
          <Styles.FormContainer className="animate__animated animate__fadeInUp">
            {isAdmin && <Styles.SelectContainer>
            <h1>Selecione o consultor(a)*</h1>
              <Styles.Select
                onChange={(event: { target: { value: number; }; }) => changeRepresentant(event.target.value)}
                required
              >
                <option value=''>Selecione</option>
                {representants.map(representant => (
                  <option key={representant.id} value={representant.id}>{representant.name}</option>
                ))}
              </Styles.Select>
            </Styles.SelectContainer>}
          <Styles.Form onSubmit={fetchSearch} isMobile={isMobile}>
            <h1>Digite o CPF do paciente</h1>
            <div className="input-container">
              <Styles.SearchInput
                  placeholder="Digite ao menos 11 caracteres numéricos"
                  required
                  value={formatCpf(cpfValue)}
                  onChange={event => handleCpfValue(event.target.value)}
                />
              <Styles.Button type="submit" className={cpfValue.length < 14 ? 'btn-disabled': ''}>
                <img src={enterIcon} alt="icone"/>
              </Styles.Button>
            </div>
          </Styles.Form>
        </Styles.FormContainer>
          <Styles.ButtonsContainer>
            <Styles.CreatePatientButton onClick={() => crateUser()} disabled={isAdmin && disableInput}>CADASTRAR NOVO PACIENTE</Styles.CreatePatientButton>
            <Styles.Cancel onClick={() => backToOrders()}>cancelar</Styles.Cancel>
          </Styles.ButtonsContainer>
        </>
  )
}
