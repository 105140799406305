import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useOrderB2c, OrderProps } from 'hooks/useOrderB2c'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'
import { CreditCart } from './CreditCart'
import { Pix } from './Pix'
import { Boleto } from './Boleto'

interface Props {
  order: OrderProps
}

export interface BarcodeInfoProps {
  amount: number
  boleto_barcode: string
  boleto_expiration_date: Date
  boleto_url: string
}


export const PaymentMethod = ({ order }: Props) => {
  const [barcodeInfo, setBarcodeInfo] = useState<any>({
    amount: 0,
    boleto_barcode: "",
    boleto_expiration_date: new Date(),
    boleto_url: ""
  })

  const [pixInfo, setPixInfo] = useState<string>('')
  const [generateBoleto, setGenerateBoleto] = useState<boolean>(false)
  const { paymentMethod, setPaymentMethod, finishOrderCheckoutByType, error, setError } = useOrderB2c()
  const {uuid} = useParams() as any

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  function AlterMethod(method: string) {
    setPaymentMethod({ type: method })
  }

  async function generateBoletoInfo(): Promise<any>{
    try{
        const response = await finishOrderCheckoutByType(uuid, paymentMethod)

        if (!response) {
          setError(true)
        }

          const payload = {
            amount: response?.data?.paymentInfos?.amount,
            boleto_barcode: response?.data?.paymentInfos?.boleto_barcode.toString(),
            boleto_expiration_date: response?.data.paymentInfos?.boleto_expiration_date,
            boleto_url: response?.data.paymentInfos?.boleto_url
          }
          setBarcodeInfo(payload)
          setPaymentMethod({ type: 'boleto' })
          setGenerateBoleto(true)
          
       return payload
    } catch (error) {
      return error
    }
  }

  async function generatePixInfo(): Promise<any>{
    try{
        const response = await finishOrderCheckoutByType(uuid, paymentMethod)

        if (!response) {
          setError(true)
        }

          const payload = response?.data?.paymentInfos?.pix_qr_code
          setPixInfo(payload)
          setPaymentMethod({ type: 'pix' })
          
       return payload

    } catch (error) {
      return error
    }
  }

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>Meio de Pagamento</Style.Title>
      <Style.Methods>
        <Style.ButtonMethod isActive={paymentMethod?.type === 'credit_card'} onClick={() => AlterMethod('credit_card')}>
          Cartão de crédito
        </Style.ButtonMethod>
        <Style.ButtonMethod isActive={paymentMethod?.type === 'pix'} onClick={() => AlterMethod('pix')}>
          Pix
        </Style.ButtonMethod>
        <Style.ButtonMethod isActive={paymentMethod?.type === 'boleto'} onClick={() => AlterMethod('boleto')}>
          Boleto bancário
        </Style.ButtonMethod>
      </Style.Methods>
      {paymentMethod?.type === 'credit_card' && <CreditCart order={order} payment={paymentMethod} />}
      {paymentMethod?.type === 'pix' && (
        <Pix
          payment={paymentMethod}
          generatePixInfo={() => generatePixInfo()}
          pixInfo={pixInfo}
          error={error}
          setPaymentMethod={setPaymentMethod}
      />
      )}
      {paymentMethod?.type === 'boleto' && (
        <Boleto
          order={order}
          payment={paymentMethod}
          barcodeInfo={barcodeInfo}
          error={error}
          generateBoletoInfo={() => generateBoletoInfo()}
          generateBoleto={generateBoleto}
          setGenerateBoleto={setGenerateBoleto}
        />
      )}
    </Style.Container>
  )
}
