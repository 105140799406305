import styled from "styled-components";

export const Container = styled.div<any>`
display: flex;
flex-direction: column;
align-items: center;
height: 100%;
/* overflow-x: ${props => props.isMobile ? 'hidden' : ''}; */
  //overflow-y: scroll;
`

export const StepsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 100%;

  .steps-name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
      color: #3D3D3D;
      font-size: 12px;
      font-weight: 600;
      margin-top: 0.8rem;
    }
  }
`
export const TableHeader = styled.div<any>`
  background-color: #f6f6f6;
  border-radius: 4px;
  height: 49px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;

  padding: 12px 48px 12px 33px;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
  }

  .product {
    width: 50%;
  }

  .product-properties {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: 30px;

    width: 50%;
  }
`

export const Products = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  width: ${props => props.isMobile ? '100%' : '80%'};
  flex-direction: column;
  margin-top: 12px;
`

export const Line = styled.span`
  margin-left: 5%;
  width: 90%;
  height: 1px;
  background: rgba(119, 119, 119, 0.5); ;
`

export const ContainerButtons = styled.div<any>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
  align-items: ${props => props.isMobile ? 'center' : ''};
  width: ${props => props.isMobile ? '100%' : '80%'};
  margin-bottom: 5rem;
  margin-top: 1rem;
`

export const CancelButton = styled.button<any>`
  border: 1px solid #115850;
  border-radius: 2px;
  color: #115850;
  padding: 1rem;
  font-weight: 500;
  cursor: pointer;

  background-color: white;
`

export const BackButton = styled.button<any>`
  background: #BBBEBD;
  border: none;
  font-weight: 500;
  border-radius: 2px;
  color: #FFFFFF;
  padding: 1rem 3rem;
  cursor: pointer;
  margin-left: 1rem;
`

export const SaveButton = styled.button<any>`
  background: ${props => props.theme.colors.quartiary};
  border-radius: 3px;
  border: none;
  padding: 1rem 3rem;
  color: #FFFFFF;
  margin-top: ${props => props.isMobile ? '1rem' : ''};
  font-weight: 600;
  cursor: pointer;

  &:disabled{
    opacity: 0.7;
    cursor: not-allowed;
  }
`
