import axios, { AxiosRequestHeaders } from 'axios'
import { deleteItem, getItem, getSessionItem } from 'utils/persistenceUtils'
import { TOKEN_KEY } from 'utils/constants'

const userToken = getSessionItem(TOKEN_KEY)
const headers: AxiosRequestHeaders = userToken ? { Authorization: `Bearer ${userToken}` } : {}

export const api = axios.create({
  baseURL: process.env.REACT_APP_HOMOL_BASE_URL,
  headers,
})

export const defaultsHeadersAxios = (token: string) => {
  api.defaults.headers.common.Authorization = token && `Bearer ${token}`
}

api.interceptors.response.use(
  response => response,
  async error => {
    const userToken = getSessionItem(TOKEN_KEY)
    if (error.response.status === 401) {
      deleteItem(TOKEN_KEY)
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)
