import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`

export const FormContainer = styled.form<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => props.isMobile ? '100%' : '70%'};
  background-color: ${props => props.theme.colors.primaryLight};
  padding: ${props => props.isMobile ? '1rem' : '3rem'};

  h1{
    color: #555358;
    text-align: left;
    font-weight: 400;
    font-size: 2rem;
    margin-right: auto;

    span{
      font-weight: bold;
    }
  }
`

export const InputContainer = styled.div<any>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};;
  width: 100%;
  gap: 20px;
  margin-top: 22px;

  .input-group{
    display: flex;
    width: 100%;
    flex-direction: column;

    span{
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* or 143% */
      color: #555358;
    }

    a{
      margin-top: 8px;
      color: #4b5dff;
    }

    b{
      padding-top: 5px;
      font-size: 14px;
      font-weight: 500;
      color: red;
    }
  }

  input{
    border: 1.45433px solid #d9d9d9;
    border-radius: 4px;
    width: 100%;
    height: 60px;
    color: #3D3D3D;
    padding-left: 10px;
    font-size: 1rem;

    &:disabled{
      background: #ffffff;
      color: #BBBEBD;
    }

    ::placeholder {
       color: lightgray;
    }
  }

  select{
    border: 1.45433px solid #d9d9d9;
    border-radius: 4px;
    width: 100%;
    height: 60px;
    color: #3D3D3D;
    font-size: 1rem;
    padding-left: 10px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 70%;
  align-items: center;

  margin-top: 16px;

  .disabled-button {
      background-color: #d9d9d9;
      color:  #bbbebd;
      pointer-events: none;
    }
`

export const ButtonSave = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 7px;
    gap: 10px;


    width: 130px;
    height: 50px;
    margin-left: 16px;

    background:  ${props => props.theme.colors.primary};
    border-radius: 2px;

    border: none;


    color: #ffffff;
    cursor: pointer;

    &:disabled{
      background-color: #d9d9d9;
      color:  #bbbebd;
      cursor: not-allowed;
    }


`

export const ButtonBack = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 7px;
    gap: 10px;

    cursor: pointer;


    width: 130px;
    height: 50px;
    margin-left: 16px;

    background: #bbbebd;
    border-radius: 2px;

    border: none;


    color: #ffffff;
`

export const InputPhoneContainer = styled.div`
  display: flex;
  border: 1.45433px solid #d9d9d9;
  border-radius: 4px;
  height: 60px;
  align-items: center;
  justify-content: center;

  button{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8e8d8d;
    font-size: 14px;
    background-color: #ffffff;
    width: 50px;
    border: 0;
    border-right: 1px solid #d9d9d9;
  }
`

export const InputPhone = styled.input`
    width: 100%;
    color: #3d3d3d;
    padding-left: 10px;
  border-radius: 0 !important;
`

export const InfoText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 4rem;

  p{
    color: #3d3d3d;
    font-size: 14px;

    a{
      text-decoration: underline;
      color:  #3d3d3d;
    }
  }

  img{
    width: 30px;
    height: 30px;
    margin-right: 0.7rem;
  }
`
