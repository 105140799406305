import React from 'react'
import ReactDOM from 'react-dom/client'
// eslint-disable-next-line import/no-unresolved
import 'react-toastify/ReactToastify.min.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Verificar se tem algum problema com esse metodo de renderização

 const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals()
