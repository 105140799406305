import styled from "styled-components";

export const Container = styled.div<any>`
  display: flex;
  align-items: ${props => (props.isMobile ? 'flex-start' : 'center')};
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  padding: 7px 42px 22px 33px;

  img {
    width: 67.08px;
    height: 70px;
  }

  .close-btn{
    cursor: pointer;
    margin-left: 10px;
    margin-right: -30px;
  }

  .btn-remove{
    background-color: transparent;
    border: none;
    color: #000000;
    text-decoration: underline;
    margin-left: -5px;
  }
`

export const ProductInfos = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '50%')};

  img {
    margin-right: ${props => (props.isMobile ? 'auto' : '')};
  }
`

export const ProductName = styled.span<any>`
  margin-left: ${props => (props.isMobile ? '24.92px' : '14.92px')};
  ${({ isMobile }) =>
    !isMobile &&
    `
    padding-right: 92px;
  `}

  font-size: ${props => (props.isMobile ? '1.1rem' : '1rem')};
`

export const Quantity = styled.div<any>`
  display: flex;
  align-items: center;
  flex-direction: column;

  .remove-button {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #c5581b;
    cursor: pointer;
    transition: color 0.6s;
    margin-top: 8px;
    border: none;
    background: transparent;
  }

  .remove-button:hover {
    color: #115850;
  }

  span {
    font-size: 12px;
  }

  select {
    width: 58px;
    height: 30px;
    text-align: center;
    border: 1px solid rgba(17, 88, 80, 0.62);
    border-radius: 2px;
    margin-top: 16px;
  }
`

export const ProductPrice = styled.span<any>`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  display: flex;


  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    flex-direction: column;
  `}

  span {
    margin-top: 1rem;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #115850;
  }
`

export const ProductTotal = styled(ProductPrice)``

export const ProductProperties = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: ${props => (props.isMobile ? '0px' : '30px')};

  width: ${props => (props.isMobile ? '100%' : '50%')};

  margin-top: ${props => (props.isMobile ? '16px' : '')};

`
