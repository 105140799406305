import styled from 'styled-components'
import { theme } from 'styles'

export const Container = styled.div<any>`
  width: 100%;
  height: 80px;
  padding: ${props => props.isMobile ? '20px 0px': '20px 10px'};
  background: ${(props) => props.theme.colors.menu};
  display: flex;
  align-items: center;
  justify-content: space-between;
  //border-bottom: 1px solid #d4d4d4;

  .content {
    padding: ${props => props.isMobile ? '0rem 0.8rem': '0rem 4rem'}; ;
    display: flex;
    width: 100%;
    align-items: center;
    height: 45px;
    justify-content: space-between;

    img {
      cursor: pointer;
      height: 120px;
      width: 120px;
      max-width: 100px;
      max-height: 70px;
      object-fit: contain;
    }
  }

  .box {
    margin: 0 13px;
    padding: 0 5px;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;

    &:last-child {
      border: none;
    }
  }

  .noitifications {
    border-right: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
  }

  .values {
    gap: 10px;

    p {
      font-size: 14px;
      color: ${theme.colors.primary};
      font-weight: 500;
    }

    span {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${theme.colors.primary};
    }
  }

  .logo-content {
    /* width: 120px; */
    /* height: 120px; */

    img{
      /* width: 120px; */
    /* height: 120px; */
      object-fit: contain;
    }
    }

`
export const BorderBottom = styled.div`
  background: ${(props) => props.theme.colors.primary};
  width: 100%;
  height: 8px;
`
