import * as Style from "./styles";

export const Disclaimer = () => {
  return(
    <Style.Container>
      <Style.Text>Prezado (a) paciente,</Style.Text>
      <Style.Text>Agradecemos por sua escolha para apoiá-lo em sua busca por mais saúde, qualidade de vida e bem-estar no seu tratamento com cannabis medicinal. <br/>
         Para garantir que seu processo seja tranquilo, pedimos a sua atenção para estas informações importantes:</Style.Text>
      <Style.Title>1.Prazo de entrega</Style.Title>
      <Style.Text>O prazo de entrega do seu pedido é de em média 25 dias úteis após a aprovação do pagamento. Dentro deste prazo, o tempo para inspeção da ANVISA pode chegar a até 15 dias úteis. <br />
        Caso o prazo se prolongue por necessidade de documentação extra ou determinação de algum órgão federal, atuamos para agilizar o processo, mas não podemos nos responsabilizar por exigências
        legais fogem ao nosso controle.</Style.Text>
      <Style.Title>2.Cancelamento do pedido</Style.Title>
      <Style.Text>O cancelamento só é possível em até 24 horas após a confirmação do pagamento, pois a partir desse prazo o processo de importação não pode ser interrompido.</Style.Text>
      <Style.Title>3.Produto com avarias</Style.Title>
      <Style.Text>Caso um ou mais produtos cheguem com avarias, solicitamos o envio imediato, logo após o recebimento, de fotos reportando o problema encontrado.<br/>
        Seguiremos prontamente com o reenvio dos itens avariados.<br/>
        Entre em contato conosco para receber orientações sobre o descarte correto do produto.</Style.Text>
      <Style.Title>4.Estorno</Style.Title>
      <Style.Text>Caso opte por não seguir com o reenvio do produto com avaria, realizaremos o estorno do valor do pedido em até 5 dias úteis.</Style.Text>
      <Style.Title>5.Recusa de Recebimento ou Devolução de Produtos</Style.Title>
      <Style.Text>Por se tratar da importação especial de produto controlado pela ANVISA, não é possível realizar a devolução deste tipo de pedido. <br/>
        Caso o paciente se recuse a receber a entrega no ato, o pedido será remetido para o local de origem acarretando um custo de envio reverso para o cliente.</Style.Text>
      <Style.Title>6.Outras informações importantes</Style.Title>
      <Style.Text>A importação de cannabis medicinal é um processo especial regulamentado pela ANVISA através da RDC 660, e não podem ser armazenados em território nacional.<br/>
        Os produtos são entregues mediante aprovação da ANVISA diretamente no endereço indicado pelo paciente.<br/><br/>
        A Cannect assessora e operacionaliza todo o processo de importação e logística para seu acesso ao tratamento.<br/><br/>
        Em caso de dúvidas ou necessidade de ajuda, entre em contato com nossa equipe de atendimento.<br/>
        Telefone: 11 99662-0535 <br />
        E-mail: ajuda@cannect.com.br <br />
      </Style.Text>
      <Style.Title>Informações adicionais:</Style.Title>
      <Style.Text>
        <a href="https://www.in.gov.br/en/web/dou/-/resolucao-rdc-n-660-de-30-de-marco-de-2022-389908959" target="_blank" rel="noreferrer">RDC 660 ANVISA</a> <br /><br />
        Esperamos que estas informações sejam úteis. Em caso de dúvidas, entre em contato conosco. <br /><br />
        Sua saúde e bem-estar são nossa prioridade.</Style.Text>
    </Style.Container>
  )
}
