import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { useState } from "react"
import { useHistory } from "react-router-dom"
import { MOBILE_WIDTH } from "utils/constants"
import {BiDownload} from "react-icons/bi"
import * as Style from "./styles"
import {api} from "../../../../services/api";
import {getApp, getBrandId} from "../../../../utils/sub-domains";
import Loading from "../../../../components/Loading";

export const OrderLink = () => {
  const [text, setText] = useState('COPIAR')
  const {linkCart} = useOrderB2c()
  const [loading, setLoading] = useState(false)
  async function copyUrl() {
    navigator.clipboard.writeText(link ?? '')
    setText('COPIADO!')
  }

  const uuid = linkCart.replace('/checkout', '')
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const history = useHistory()

  const link = `${window.location.origin}/checkout/${linkCart}`

  function backToOrders (){
    history.push("/pedidos")
  }

  async function downloadPdf(){
    setLoading(true)
    const brandId = getBrandId()
    const brandName = getApp()
    await api.get (`order_budged/${uuid}/${brandId}`, {responseType: 'arraybuffer'})
      .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]
        ,{type: "application/pdf"}))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${brandName}.pdf`);
      document.body.appendChild(link);
      link.click();
      setLoading(false)
    }).catch(err => setLoading(false))
  }
  return (
    <Style.Container isMobile={isMobile}>
      {loading && <Loading loading={loading} /> }
      <Style.Box isMobile={isMobile}>
        <Style.Texts isMobile={isMobile}>
          <h2>URL DO ORÇAMENTO</h2>
          <a href={link}>{link}</a>
        </Style.Texts>
        <Style.CopyButton isMobile={isMobile} onClick={() => copyUrl()}>
          {text}
        </Style.CopyButton>
      </Style.Box>
      <Style.BoxPdfDownload onClick={() => downloadPdf()}>
        <span>Download PDF</span>
        <BiDownload size={22}/>
      </Style.BoxPdfDownload>
      <Style.FinishedButton onClick={() => backToOrders()}>
        FINALIZAR
      </Style.FinishedButton>
    </Style.Container>
  )
}
