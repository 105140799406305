import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { formatBirthday, formatCpf, formatPhone, formatZipCode } from 'utils/formatters'
// eslint-disable-next-line import/no-unresolved
import 'animate.css'
import { useHistory } from 'react-router-dom'
import { useOrderB2c } from 'hooks/useOrderB2c'
import { validBirthDate, validCPF, validCep, validEmail, validName, validPhone } from 'utils/validations'
import Loading from 'components/Loading'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import info from '../../../../assets/img/info.png'
import * as Styles from './styles'

export interface ErrorFieldPropos {
  name?: string
  email?: string
  cpf?: string
  phone_residencial?: string
  formatBirthday?: string
  phone?: string
  cep?: string
  address?: string
  complement?: string
  district?: string
  state?: string
  city?: string
  number?: string
}

type PersonAddressFields = 'cep' | 'state' | 'city' | 'complement' | 'district' | 'number' | 'address'

export type PersonFields = 'email' | 'name' | 'cpf' | 'phone' | 'formatBirthday' | 'phone_residencial' | ''

const fuList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
]

export const FormUser = () => {
  const [errorField, setErrorField] = useState<ErrorFieldPropos>({})
  const [disabled, setDisabled] = useState<boolean>(false)
  const { person, personAddress,setActiveForm, updatePerson, changePerson, changePersonAddress, setOrderFinished, setLoading, loading, inputDisabled, setIsModalOpen } = useOrderB2c()

  const history = useHistory()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const backToSearchForm = (e: FormEvent) => {
    e.preventDefault()
    setActiveForm('search-user')
  }

  async function saveUser(e: FormEvent) {
    e.preventDefault()
    setLoading(true)
    await updatePerson()
      .then(uuid => {
        console.log(uuid)
        setLoading(false)
        setOrderFinished(false)
        history.push(`/b2b/criar-pedido/carrinho/${uuid}`)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const handlePerson = (personType: PersonFields, value: string) => {
    if (personType === "name") {
      const isValid = validName(value)
      if (!isValid) {
        setErrorField({...errorField, name: 'Nome inválido!'})
        setDisabled(true)
      } else{
        setErrorField({...errorField, name: ''})
        setDisabled(false)
      }
    } else if (personType === 'email') {
      const isValid = validEmail(value)
      if (!isValid) {
        setErrorField({...errorField, email: 'E-mail inválido'})
        setDisabled(true)
      } else{
        setErrorField({...errorField, email: ''})
        setDisabled(false)
      }
    } else if (personType === 'cpf') {
      const isValid = validCPF(value)
      if (!isValid) {
        setErrorField({...errorField, cpf: 'CPF inválido'})
        setDisabled(true)
    } else{
      setErrorField({...errorField, cpf: ''})
      setDisabled(false)
    }
    } else if (personType === 'phone_residencial') {
      const isValid = validPhone(value)
      if (!isValid) {
        setErrorField({...errorField, phone_residencial: 'Telefone inválido'})
      } else{
        setErrorField({...errorField, phone_residencial: ''})
        setDisabled(false)
      }
    } else if (personType === 'formatBirthday') {
      const isValid = validBirthDate(value)
      if (!isValid) {
        setErrorField({...errorField, formatBirthday: 'Data inválida'})
        setDisabled(true)
      } else{
        setErrorField({...errorField, formatBirthday: ''})
        setDisabled(false)
      }
    } else if (personType === 'phone') {
      const isValid = validPhone(value)
      if (!isValid) {
        setErrorField({...errorField, phone: 'Celular / Whatsapp inválido'})
        setDisabled(true)
      } else{
        setErrorField({...errorField, phone: ''})
        setDisabled(false)
      }
    }

    changePerson(personType, value, 'formInput')
  }

  const isEmptyInput = !person.name || !person.phone || !person.email || !person.cpf || !person.formatBirthday  || !personAddress.cep || !personAddress.state || !personAddress.city || !personAddress.district || !personAddress.number || !personAddress.address

  useEffect(() => {
    setIsModalOpen(false)
  }, [])


  const handleAddress = (AddressType: PersonAddressFields, value: string) => {
    if (AddressType === "cep") {
      const isValid = validCep(value)
      if (!isValid) {
        setErrorField({...errorField, cep: 'CEP inválido'})
        setDisabled(true)
      } else{
        setErrorField({...errorField, cep: ''})
        setDisabled(false)
      }
    }

      changePersonAddress(AddressType, value)
  }

  // useEffect(() => {    console.log('personAddress-->', personAddress)

  // }, [personAddress])

  return (
    <Styles.Container>
      {loading && <Loading loading/>}
      <Styles.FormContainer className="animate__animated animate__fadeInUp" isMobile={isMobile} onSubmit={(e: FormEvent<Element>) => saveUser(e)}>
        <h1>Cadastro de <span>Paciente</span></h1>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Nome Completo*</span>
            <input
              value={person?.name}
              type="text"
              placeholder="Digite o nome"
              onChange={event => handlePerson('name', event.target.value)}
              required
              disabled={inputDisabled}
            />
            {errorField.name !== '' && (
              <b>{errorField.name}</b>
            )}
          </div>
        </Styles.InputContainer>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Email*</span>
            <input
              value={person?.email}
              type="email"
              placeholder="Digite um e-mail válido"
              onChange={event => handlePerson('email', event.target.value)}
              required
            />
            {errorField.email !== '' && (
              <b>{errorField.email}</b>
            )}
          </div>
          <div className="input-group">
            <span>CPF*</span>
            <input
              value={formatCpf(person?.cpf)}
              type="text"
              placeholder="Digite o CPF"
              maxLength={14}
              onChange={event => handlePerson('cpf', event.target.value)}
              required
              disabled={inputDisabled}
            />
            {errorField.cpf !== '' && (
              <b>{errorField.cpf}</b>
            )}
          </div>
        </Styles.InputContainer>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Telefone (opcional)</span>
            <Styles.InputPhoneContainer>
              <Styles.InputPhone
                value={formatPhone(person?.phone_residencial)}
                type="text"
                placeholder="11 9999-9999"
                maxLength={14}
                onChange={event => handlePerson('phone_residencial', event.target.value)}
              />
            </Styles.InputPhoneContainer>
            {errorField.phone_residencial !== '' && (
              <b>{errorField.phone_residencial}</b>
            )}
          </div>
          <div className="input-group">
            <span>Data de nascimento*</span>
            <input
              value={formatBirthday(person?.formatBirthday)}
              type="text"
              placeholder="00/00/0000"
              maxLength={10}
              onChange={event => handlePerson('formatBirthday', event.target.value)}
              required
              disabled={inputDisabled}
            />
            {errorField.formatBirthday !== '' && (
              <b>{errorField.formatBirthday}</b>
            )}
          </div>
        </Styles.InputContainer>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Celular/WhatsApp*</span>
            <Styles.InputPhoneContainer>
              <button type="button">+55</button>
              <Styles.InputPhone
                value={formatPhone(person?.phone)}
                type="text"
                placeholder="11 99999-9999"
                maxLength={15}
                onChange={event => handlePerson('phone', event.target.value)}
              />
            </Styles.InputPhoneContainer>
            {errorField.phone !== '' && (
              <b>{errorField.phone}</b>
            )}
          </div>
          <div className="input-group" />
        </Styles.InputContainer>
        <Styles.InfoText>
          <img src={info} alt="info"/>
          <p>
            Se deseja alterar CPF, nome ou data de nascimento, <br />
            por favor entre em contato com o <a href="https://whts.co/Cannect" target="_blank" rel="noreferrer">atendimento Cannect</a>.
          </p>
        </Styles.InfoText>
        <h1><span>Endereço</span></h1>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group" style={{width: '50%'}}>
            <span>CEP*</span>
            <input
              value={formatZipCode(personAddress?.cep)}
              id="cep"
              type="text"
              maxLength={9}
              placeholder="Digite seu CEP"
              onChange={event => handleAddress('cep', event.target.value)}
            />
            {errorField.cep !== '' && (
              <b>{errorField.cep}</b>
            )}
            <a href="https://buscacep.com.br" target="_blank" rel="noreferrer">não sei meu cep</a>
          </div>
          <div className="input-group">
            <span>Logradouro*</span>
            <input
              id="street"
              value={personAddress?.address}
              type="text"
              placeholder="Rua, avenida, praça"
              onChange={event => handleAddress('address', event.target.value)}
            />
          </div>
        </Styles.InputContainer>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Complemento (opcional)</span>
            <input
              value={personAddress?.complement}
              id="cep"
              type="text"
              placeholder="Bloco, apartamento e etc"
              onChange={event => handleAddress('complement', event.target.value)}
            />
          </div>
          <div className="input-group">
            <span>Bairro*</span>
            <input
              id="street"
              value={personAddress?.district}
              type="text"
              placeholder="Digite seu bairro"
              onChange={event => handleAddress('district', event.target.value)}
            />
          </div>
          <div className="input-group">
            <span>Estado*</span>
            <select
              required
              value={personAddress?.state}
              onChange={event => handleAddress('state', event.target.value)}
            >
              <option>Selecione seu estado</option>
              {fuList.map(fu => (
                <option key={fu}>{fu}</option>
              ))}
            </select>
          </div>
        </Styles.InputContainer>
        <Styles.InputContainer isMobile={isMobile}>
          <div className="input-group">
            <span>Cidade</span>
            <input
              value={personAddress?.city}
              id="cep"
              type="text"
              placeholder="Digite sua cidade"
              onChange={event => handleAddress('city', event.target.value)}
            />
          </div>
          <div className="input-group">
            <span>Número*</span>
            <input
              id="street"
              value={personAddress?.number}
              type="text"
              placeholder="Digite o número da sua casa"
              onChange={event => handleAddress('number', event.target.value)}
            />
          </div>
          <div className="input-group" />
        </Styles.InputContainer>
      </Styles.FormContainer>
      <Styles.ButtonContainer>
        <Styles.ButtonBack type="button" onClick={e => backToSearchForm(e)}>
          VOLTAR
        </Styles.ButtonBack>
        <Styles.ButtonSave  disabled={disabled || isEmptyInput} onClick={(e) => saveUser(e)}>SALVAR</Styles.ButtonSave>
      </Styles.ButtonContainer>
    </Styles.Container>
  )
}
