import styled from "styled-components";

export const FormContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Form = styled.form<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  h1{
    color: #646464;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: left;
  }

  .input-container{
    display: flex;
    width: ${props => props.isMobile ? '100%' : '40%'};
  }

  .btn-disabled {
    height: 60px;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 70px;
    pointer-events: none;
    border: none;
    background-color:#646464;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    img{
      margin-top: 20px;
      width: 20px;
      height: 20px;
    }
  }
`

export const SearchInput = styled.input`
  width: 100%;
  height: 60px;
  border: none;
  font-size: 1.1rem;
  background: ${props => props.theme.colors.inputBg};
  border-radius: 4px 0px 0px 4px;
  padding-left: 16px;
  text-align: center;
  &::placeholder{
    text-align: center;
    color: rgba(100, 100, 100, 0.55);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }

`
export const Button = styled.button`
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 70px;
  cursor: pointer;
  border: none;
  background-color:${props => props.theme.colors.primary};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  img{
    margin-top: 20px;
    width: 20px;
    height: 20px;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

export const CreatePatientButton = styled.button`
  margin-top: 22px;
  color: #000000;
  border: 2px solid ${props => props.theme.colors.tertiary};;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  height: 40px;
  width: 250px;

  &:disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }
`

export const Cancel = styled.button`
  margin-top: 27px;
  color: #555358;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 600;
`

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h1{
    color: #646464;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: left;
  }
`

export const Select = styled.select<any>`
  width: ${props => props.isMobile ? '100%' : '40%'};
  height: 60px;
  border: none;
  font-size: 1.1rem;
  background: ${props => props.theme.colors.inputBg};
  border-radius: 4px 0px 0px 4px;
  text-align: center;
  option{
    text-align: center;
    color: rgba(100, 100, 100, 0.55);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`
