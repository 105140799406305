import styled, { css } from 'styled-components'
import ITCAvantGardeStd from 'assets/fonts/ITCAvantGardeStd-Bk.ttf'
import ITCAvantGardeStdBold from 'assets/fonts/ITCAvantGardeStdBold.ttf'
import { theme } from 'styles'
import { TextProps } from '../../types/TextTypes'

export const changeFont = {
  h1: css`
    font-size: ${theme.fontSize.h1};
  `,
  h2: css`
    font-size: ${theme.fontSize.h2};
  `,
  display1: css`
    font-size: ${theme.fontSize.disable1};
  `,
  display2: css`
    font-size: ${theme.fontSize.disable2};
  `,
  sml: css`
    font-size: ${theme.fontSize.sml};
  `,
  md: css`
    font-size: ${theme.fontSize.md};
  `,
  lg: css`
    font-size: ${theme.fontSize.lg};
  `,
  xsml: css`
    font-size: 11px;
  `,
}
export const changeColor = {
  primary: css`
    color: ${theme.colors.primary};
  `,
  secondary: css`
    color: ${theme.colors.secondary};
  `,
  tertiary: css`
    color: ${theme.colors.tertiary};
  `,
  pathway: css`
    color: ${theme.colors.pathway};
  `,
  white: css`
    color: ${theme.colors.white};
  `,
  black: css`
    color: ${theme.colors.black};
  `,
  gray: css`
    color: ${theme.colors.gray};
  `,
}

export const Container = styled.span<TextProps>`
  @font-face {
    font-family: ${({ bold }) => (bold ? 'ITC Avant Garde Gothic Std Bold' : 'ITC Avant Garde Gothic Std Bk')};
    src: url(${({ bold }) => (bold ? ITCAvantGardeStdBold : ITCAvantGardeStd)});
  }
  h1,
  h2,
  p,
  span {
    font-family: ${({ bold }) => (bold ? 'ITC Avant Garde Gothic Std Bold' : 'ITC Avant Garde Gothic Std Bk')};
    ${({ type }) => changeFont[type || 'md']};
    ${({ color }) => changeColor[color || 'black']};
    margin: 0;
    padding: 0;
  }
  margin: 0;

  font-family: ${({ bold }) => (bold ? 'ITC Avant Garde Gothic Std Bold' : 'ITC Avant Garde Gothic Std Bk')};
  ${({ type }) => changeFont[type || 'md']};
  ${({ color }) => changeColor[color || 'black']};
`
