import { useEffect } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import Skeleton from 'react-loading-skeleton'
import * as Style from './styles'
import 'react-loading-skeleton/dist/skeleton.css'
import {useOrderB2c} from "../../../hooks/useOrderB2c"

const options = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 21, 23, 24, 25, 26, 27, 28, 29, 30, 31, 31, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70
]
interface Item {
  id: number
  name: string
  real_price: number
  quantity: number
  image: string
}

export const Product = ({id, image, name, quantity, real_price}: Item) => {
  const { removeCart, setQuantity, couponIsValid, couponResponse, getDiscount, itens } = useOrderB2c()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  async function handleSelect(event: string, id: number) {
    if (parseInt(event, 10) === 0) {
      // eslint-disable-next-line react/destructuring-assignment
      removeCart(id)
    } else {
      setQuantity(id, parseInt(event, 10))
    }
  }

  useEffect(() => {
    if (couponIsValid) {
      getDiscount(itens, couponResponse.coupon.name)
    }
  }, [quantity])

  // eslint-disable-next-line no-unsafe-optional-chaining
  const total = real_price * quantity

  return (
    <Style.Container key={id} isMobile={isMobile}>
      <Style.ProductInfos isMobile={isMobile}>
        {!image ? <Skeleton style={{width: '60px', height: '60px'}}/> : <img src={image} alt="foto produto" />}
        {!name ? <Skeleton style={{width: '250px', marginLeft: '20px'}}/> : <Style.ProductName isMobile={isMobile}>{name}</Style.ProductName>}

      </Style.ProductInfos>

      <Style.ProductProperties isMobile={isMobile}>
        {!quantity ? (
          <Skeleton style={{width: '50px', height: '50px'}}/>
        ) : (
          <Style.Quantity>
            {quantity}
          </Style.Quantity>
        )}
        {!real_price && real_price !== 0 ? (
            <Skeleton style={{width: '50px'}}/>
          ) : (
            <Style.ProductPrice isMobile={isMobile}>
              {isMobile && <span>VALOR UNITÁRIO</span>}
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
                currencyDisplay: 'symbol'
              }).format(real_price)}
            </Style.ProductPrice>
          )
        }
        {!total && real_price !== 0 ? (
            <Skeleton style={{width: '50px'}}/>
          ) : (
            <Style.ProductTotal isMobile={isMobile}>
            {isMobile && <span>VALOR TOTAL</span>}

            {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol'
            }).format(total)}
          </Style.ProductTotal>
          )
        }
      </Style.ProductProperties>
    </Style.Container>
  )
}
