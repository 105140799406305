import { USER_CONFIG } from './constants'

const setItem = (key: string, item: string) => {
  console.log(key, item);

  localStorage.setItem(key, item)
}

const getItem = (key: string) => localStorage.getItem(key)
const getSessionItem = (key: string) => sessionStorage.getItem(key)
const deleteItem = (key: string) => localStorage.removeItem(key)

const setItemUser = (data: any) => {
  setItem(USER_CONFIG, JSON.stringify(data))
}
const getUserStorage = () => JSON.parse(<string>localStorage.getItem(USER_CONFIG))

export { setItem, getItem, deleteItem, setItemUser, getUserStorage, getSessionItem }
