import Loading from "components/Loading"
import useMediaQuery from "hooks/useMediaQuery"
import { useOrderB2c } from "hooks/useOrderB2c"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { MOBILE_WIDTH, routes } from "utils/constants"
import { Connector } from "../StepsNavigator/Connector"
import { StepButton } from "../StepsNavigator/StepButton"
import { Documents } from "./Documents"
import { OrderLink } from "./OrderLink"
import { Product } from "./Product"
import { Resume } from "./Resume"
import { SelectProducts } from "./SelectProducts"
import * as Style from "./styles"

export const Cart = () => {
  const {getProducts, loading, itens, createOrder, orderFinished, recipeSend, setShowFormCreateUser, clearOrder, setActiveForm} = useOrderB2c()

  const allItensSend = recipeSend && itens.length > 0

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  useEffect(() => {
    getProducts()
  }, [])

  const history = useHistory()

  const backToFormUser = () => {
    setShowFormCreateUser(true)
    history.push(routes.B2B_CREATE_ORDER_IDENTIFICATION)
  }

  const backOrders = () => {
    setActiveForm('search-user')
    clearOrder()
    history.push(routes.PEDIDOS)
  }

  return (
    <Style.Container isMobile={isMobile}>
      {loading && <Loading loading/>}
      <Style.StepsContainer>
        <div className='steps-name'>
          <StepButton step="identification" active disabled={false} />
          {!isMobile && <span>identificação</span>}
        </div>


        <Connector active={false} disabled />
        <div className='steps-name'>
          <StepButton step="select-products" active disabled={false} />
          {!isMobile && <span>seleção de produtos</span>}
        </div>


        <Connector active={false} disabled />
        <div className='steps-name'>
          <StepButton step="cart" disabled active={false} />
          {!isMobile && <span>orçamento</span>}
        </div>
      </Style.StepsContainer>

      {!orderFinished ? (
        <>
          <Documents />
          <SelectProducts />
          {!isMobile && (
            <Style.TableHeader>
              <div className="product">
                <p>produto</p>
              </div>
              <div className="product-properties">
                <p>quantidade</p>
                <p>valor unitário</p>
                <p>valor total</p>
              </div>
            </Style.TableHeader>
          )}
          <Style.Products isMobile={isMobile}>
            {itens.map((product, index) => (
              <>
                {index > 0 && <Style.Line />}
                <Product
                  id={product?.id}
                  name={product?.translated_name}
                  real_price={product?.real_price}
                  quantity={product?.quantity}
                  image={product?.image ? product?.image :'https://s3.amazonaws.com/cannect.portal.upload/produtos/14516/foto%201.jpg'}
                />
              </>
            ))}
          </Style.Products>

          <Resume />

          <Style.ContainerButtons isMobile={isMobile}>
            <div>
              <Style.CancelButton isMobile={isMobile} onClick={backOrders}>CANCELAR</Style.CancelButton>
              <Style.BackButton isMobile={isMobile} onClick={backToFormUser}>VOLTAR</Style.BackButton>
            </div>
            <Style.SaveButton isMobile={isMobile} disabled={!allItensSend} onClick={() => createOrder()}>SALVAR E ENVIAR PARA O PACIENTE</Style.SaveButton>
          </Style.ContainerButtons>
        </>
      ) : (
        <OrderLink />
      )}

    </Style.Container>
  )
}
