import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 36%;
`

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 2rem;
  width: 64%;
`

export const OrderStatus = styled.div<any>`
   span{
    font-size: 0.8rem;
    padding: 0.6rem;
    color: white;
    border-radius: 999px;
    background-color: ${props => props.color};
  }
`

export const OrderStatusTitle = styled.div<any>`

  h3{
    font-size: 0.8rem;
    font-weight: 400;
  }
`

export const OrderPaymentStatusTitle = styled.div<any>`
  h3{
    font-size: 0.8rem;
    font-weight: 400;
  }
`
export const OrderPaymentStatus = styled.div<any>`
  span{
    font-size: 0.8rem;
    color: white;
    padding: 0.6rem;
    border-radius: 999px;
    background-color: ${props => props.color};
  }
`
  export const OrderPeople = styled.div`
    p{
      color: black;
      font-size: 0.8rem;
      text-align: right;
      font-weight: 300;
    }
  `
  export const OrderPeopleTitle = styled.div`
   h3{
    font-size: 0.8rem;
    font-weight: 400;
  }
  `

export const OrderValue = styled.div`
  margin-bottom: 1rem;
  span{
    font-weight: bold;
  }
`
export const OrderValueTitle = styled.div`
  h3{
    font-size: 0.8rem;
    font-weight: 400;
  }
`

export const OrderDetails = styled.div`
margin-bottom: 1.5rem;
span{
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;

  svg{
    width: 20px;
    height: 20px;
    margin-left: 10px;
    color: ${props => props.theme.colors.primary};
  }
}
`
