import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

const Steps = () => {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  return (
    <Style.Container>
      <Style.ArrowSteps isMobile={isMobile}>
        <Style.Step current done isMobile={isMobile}>
          <span> Documentação</span>
        </Style.Step>
        <Style.Step isMobile={isMobile}>
          <span>Envio</span>
        </Style.Step>
        <Style.Step isMobile={isMobile}>
          <span>Entregue</span>
        </Style.Step>
      </Style.ArrowSteps>
    </Style.Container>
  )
}

export default Steps
