import styled from "styled-components";

export const FooterContainer = styled.div`
  /* position: absolute; */
  /* bottom: 0; */
  margin-top: auto;
  width: 100%;
  height: 20px;
  background: ${props => props.theme.colors.primary};
`
