import styled from "styled-components";

export const Container = styled.div<any>`
  background-color: #F3F3F3;
  width: 100%;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-x: scroll;
`
export const Header = styled.div<any>`
  width: ${props => props.isMobile ? '100%': '80%'};
  margin-top: 5px;
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column': 'row'};
  justify-content: space-between;
  align-items: center;
`

export const ContainerButtons = styled.div`
  display: flex;
`

export const BtnNewOrder = styled.button<any>`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  width: ${props => props.isMobile ? '100%': ''};
  padding: ${props => props.isMobile ? '0.7rem 1rem' : '1rem 2rem'};
  cursor: pointer;
`

export const InfoOrders = styled.div<any>`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: ${props => props.isMobile ? '1rem': ''};
`

export const OrdersAmount = styled.span`
  color: #115850;
  font-size: 0.9rem;
`

export const OrdersTotal = styled.span<any>`
  color: #115850;
  text-align: ${props => props.isMobile ? 'center': ''};
  font-size: 0.9rem;
`
export const InputContainer = styled.div<any>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  height: 40px;

  svg{
    width: 30px;
    height: 30px;
    padding-right: 10px;
  }

  input{
    border: none;
    padding-left: 1rem;
    width: ${props => props.isMobile ? '100%' : '250px'};
    background-color: white;

    ::placeholder {
       color: lightgray;
    }
  }
`

export const Hr = styled.div<any>`
  width: ${props => props.isMobile ? '100%': '80%'};
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 20px 0px;
`
