import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 25px;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  input {
    background: #ffffff;
    border: 1px solid #dddddd;
    height: 40px;

    font-weight: 300;
    padding-left: 8px;

    &:disabled {
      opacity: 0.7;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  select {
    background: #ffffff;
    border: 1px solid #dddddd;
    height: 40px;

    font-weight: 300;
    padding-left: 8px;
  }

  .select-installments {
    * {
      font-family: 'Nunito', sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: '#3D3D3D';
    }
  }

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 13px;

    color: #777777;
    margin-bottom: 5px;
  }

  .input-column {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 16px;
    }
  }

  .input-groups {
    display: flex;
    gap: 25px;

    div {
      display: flex;
      flex-direction: column;
      width: 100%;

      input {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }

  .ant-select-selector {
    border-radius: 0 !important;
    border-color: #dddddd !important;
    box-shadow: none !important;
    &:hover {
      border-color: #dddddd !important;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const BtnFinishOrder = styled.button`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 5px;
  height: 45px;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  width: 180px;
  cursor: pointer;
  `
export const ModalPatientIsNotUserContent = styled.div`
width: 320px;
display: flex;
flex-direction: column;

strong {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: #21976e;
}

span {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #000000;
  text-align: center;
}

button {
  border: none;
  background: #c5581b;
  border-radius: 2px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  text-align: center;
  height: 40px;
  color: white;
  margin-top: 16px;
}
`

export const ModalContent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    color: red;
  }

  button {
    border: none;
    background: #c5581b;
    border-radius: 2px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    height: 40px;
    color: white;
    margin-top: 16px;
  }
`

export const OptionInstallment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  * {
    font-family: 'Nunito', sans-serif;
    color: rgba(61, 61, 61, 1);
  }

  .values_installment {
  }

  .not_installment {
    color: '#6BA543' !important;
  }
`
