import styled from 'styled-components'
import { theme } from 'styles'

export const Container = styled.div<any>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: ${props => props.theme.colors.login};

  input{
    font-size: 1rem;

    ::placeholder {
       color: lightgray;
    }
  }

  h1{
    text-align: left;
    color: #3D3D3D;
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 3rem;
    margin-right: 25%;
  }

  .left-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .right-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.colors.menu};

    img{
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  .inputs {
    width: ${props => props.isMobile ? '80%' : '500px'};
    background-color: white;
    height: 55px;
    border: none;
    padding: 1rem;
    border-bottom: 2.45863px solid #D9D9D9;
    margin: ${theme.spacing.space2} 0;
  }
  .action {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
  }
`

export const BtnForgotPassword = styled.button`
  all: 'unset';
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #3D3D3D;
  cursor: pointer;
`

export const BtnLogin = styled.button`
  border: none;
  background-color: ${props => props.theme.colors.loginBtn};
  color: white;
  height: 50px;
  width: 20%;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  margin-top: 1rem;
  cursor: pointer;
`
