import styled from "styled-components";

export const Container = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  height: 100px;
  background-color: white;
  margin-top: 1rem;
`

export const Data = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '80px'};
  color: #646464;
  font-weight: 500;
`

export const StatusOrder = styled.div<any>`
  span{
    width: ${props => props.isMobile ? '100%' : '200px'};
    border: 1px solid ${props => props.color ?? '#E0AF00'};
    border-radius: 25px;
    padding: 0.8rem;
    color: #646464;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`

export const StatusPayment = styled.div<any>`
  span{
    width: ${props => props.isMobile ? '100%' : '200px'};
    background-color: ${props => props.color ?? '#74B06C'};
    border-radius: 25px;
    padding: 0.8rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`
export const Date = styled.div<any>`
  display: flex;
  width:  ${props => props.isMobile ? '100%' : '40px'};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span{
    color: #646464;
  }
`
export const People = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '140px'};
  color: #646464;
  text-align: center;
`
export const Value = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '80px'};
  color: #646464;
  text-align: center;
`
export const Details = styled.div<any>`
  svg{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`
