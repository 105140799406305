import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { routes, TOKEN_KEY } from 'utils/constants'
import Layout from 'components/Layout'
import { getItem, getSessionItem } from 'utils/persistenceUtils'
import { defaultsHeadersAxios } from 'services/api'
import { Identification } from 'pages/OrderB2c/Identification'
import { Cart } from 'pages/OrderB2c/Cart'
import { Orders } from 'pages/Orders'
import OrderStatusAttendant from 'pages/OrderB2c/OrderStatusAttendant'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import Logout from '../pages/Logout'
import PrivateRoute from './PrivateRoutes'
import OrderStatus from "../pages/OrderB2c/OrderStatus";
import {Checkout} from "../pages/Checkout";

const Routes = () => {
  const history = useHistory()
  const location = useLocation()

  // useEffect(() => {
  //   const token = getSessionItem(TOKEN_KEY)
  //   if (token) {
  //     defaultsHeadersAxios(token)
  //   } else {
  //     history.push(routes.LOGIN)
  //   }
  // }, [location.pathname])

  return (
    <Switch>
      <Route exact path={routes.LOGIN} component={Login} />

      {/* <NoSideBar>
        <Route path={routes.B2C_CREATE_ORDER_IDENTIFICATION} component={Identification} />
      </NoSideBar> */}
      <Layout>
        <Route path={routes.B2B_CREATE_ORDER_IDENTIFICATION} component={Identification} />
        <Route path={routes.B2B_CREATE_ORDER_CART} component={Cart} />
        <PrivateRoute exact path={routes.HOME} component={Dashboard} />
        <Route exact path={routes.B2B_ORDER_STATUS_USER} component={OrderStatus} />
        <PrivateRoute exact path={routes.B2B_ORDER_STATUS} component={OrderStatusAttendant} />
        <Route exact path={routes.CHECKOUT} component={Checkout} />
        <Route exact path={routes.PEDIDOS} component={Orders} />
      </Layout>
    </Switch>
  )
}

export default Routes
