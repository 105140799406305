import styled from 'styled-components'

export const Container = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  width: ${props => (props.isMobile ? '100%' : '50%')};
  padding: 26px 35.17px 16px 26px;
`

export const Title = styled.h2`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  text-transform: uppercase;

  color: #000000;
  margin-bottom: 30px;
`
export const Methods = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 25px;
`

interface ButtonMethodProps {
  isActive?: boolean
}

export const ButtonMethod = styled.button<ButtonMethodProps>`
  background: ${props => (props.isActive ? '#C5581B' : '#ffffff')};
  border: ${props => (props.isActive ? 'none' : '1px solid #c5581b')};
  border-radius: 2px;
  width: 50%;
  height: 35px;
  color: ${props => (props.isActive ? '#FFFFFF' : '#C5581B')};
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;

  text-align: center;
`
