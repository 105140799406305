import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Col, Row, Statistic } from 'antd'

const CountDown = ({ generatePixInfo, payment }: any) => {
  const [pixExpired, setPixExpired] = useState<boolean>(false)

  const {uuid} = useParams() as any

  const { Countdown } = Statistic;
  const time = Date.now() + 1000 * 60 * 60 * 24 * 2 / 580
  const deadline = !pixExpired ? time : 0;

  const onFinish = () => {
    generatePixInfo(uuid, payment)
    setPixExpired(true)
  }

  useEffect(() => {
    setPixExpired(false)
  }, [pixExpired])

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Countdown value={deadline} onFinish={onFinish} />
      </Col>
    </Row>
  );
};

export default CountDown;