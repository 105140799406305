import styled from "styled-components";

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.isMobile ? '2rem' : '4rem')};
  width:  ${props => (props.isMobile ? '100%' : '80%')};
  .horizontal-uploads {
    display: flex;
    flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
    justify-content: center;
    width: 100%;
    gap: 12px;
    margin-bottom: 16px;
  }

  h1{
    color: #555358;
    font-size:  ${props => (props.isMobile ? '1.2rem' : '1.7rem')};
    margin-right: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 400;

    span{
      font-weight: bold;
    }
  }
`

export const UploadWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f6f6;
  border-radius: 2px;
  height: ${props => (props.isMobile ? '100%' : '106px')};

  width: 100%;

  .upload-content{
    padding: 26px 35.17px 16px 26px;
  }
`

export const TitleUpload = styled.h2<any>`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: ${props => (props.isMobile ? '19px' : '18px')};
`

export const NameFile = styled.span`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-top: 19px;

  color: #646464;
`

export const ButtonUpload = styled.button<any>`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 14px;
  width: ${props => (props.isMobile ? '100px' : '100px')};
  height: ${props => (props.isMobile ? '100px' : '')};
  max-width: ${props => (props.isMobile ? '60px' : '100px')};
  text-transform: uppercase;
  /* margin-left: 55px; */
  /* margin-top: -10px; */
  color: #fff;
  cursor: pointer;
  background-color: ${props => props.theme.colors.primary};

  svg {
    color: #fff;
    width: 19px;
    height: 19px;
    margin-left: 7px;
    margin-top: 10px;
  }
`

export const CheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
    width: 400px;
    color: #777777;
  }
`
export const ShowFormPrescriber = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100px;
  cursor: pointer;

  svg {
    color: #115850;
    width: 30px;
    height: 30px;
  }

`
export const AddPrescriber = styled.div<any>`
  width: 100%;
  height: ${props => props.isMobile ? '100%': ''};
  overflow-x: hidden;
  background: #E3E3E3;
  border-radius: 0px 0px 4px 4px;
`

export const PrescriberForm = styled.div<any>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-left: 28px;
  padding-right: 20px;
  /* align-items: center; */
  padding-bottom: 20px;

  /* justify-content: space-between; */
`

export const RowForm = styled.div<any>`
  display: flex;
  /* flex-direction: ${props => props.isMobile ? 'column': 'row'}; */
  align-items: center;
  gap: 10px;
  margin-top: 16px;
  overflow-x: hidden;
`

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input{
    background: #F3F3F3;
    border-radius: 2px;
    height: 40px;
    border: none;
    padding-left: 10px;
    margin-top: 10px;
    width: 100%;
  }

  select{
    background: #F3F3F3;
    border-radius: 2px;
    height: 40px;
    border: none;
    padding-left: 10px;
    margin-top: 10px;
    width: 100%;
  }

  #name{
    width: 100%;
  }

  span{
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;

    color: #646464;
  }
`
export const SearchPrescriberButton = styled.button`
  all: unset;
  border: 1px solid #000;
  border-radius: 4px;
  height: 38px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100px;
  margin-left: auto;


  &:disabled{
    cursor: not-allowed;
    opacity: 0.6;
  }

  cursor: pointer;
  svg{
    width: 20px;
    height: 20px;
  }
`
export const SavePrescriberButton = styled.button`
  all: unset;
  border-radius: 4px;
  height: 40px;
  background-color: ${props => props.theme.colors.primary};
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100px;
  cursor: pointer;
  margin-left: auto;

  &:disabled{
    cursor: not-allowed;
    opacity: 0.6;
  }
`


export const ModalContent = styled.div``

export const ModalTitle = styled.div`
  margin-bottom: 1rem;
`

export const ModalText = styled.div``

export const ModalButton = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.theme.colors.primary};
  color: #fff;
  border: none;
  padding: 0.5rem 0;
`
