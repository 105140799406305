import React, { useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import { format } from 'date-fns'
import * as Styles from './styles'
import Text from '../Text'

interface AccordionProps {
  title?: any
  children?: React.ReactNode
  date: Date
}
const AccordionFilter = ({ title, children, date }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Styles.Container isOpen={isOpen}>
      <div className="header-accordion_filter">
        <div>
          <p>
            {title}
          </p>
        </div>
        <div className='date'>
          <span>{format(new Date(date), 'dd/MM/yyyy')}</span>
        </div>
        <button type="button" onClick={() => setIsOpen(!isOpen)} className="button-accordion">
          {isOpen ? <FiMinusCircle /> : <FiPlusCircle />}
        </button>
      </div>
      <Styles.Wrapper isOpen={isOpen}>
        <div>{children}</div>
      </Styles.Wrapper>
    </Styles.Container>
  )
}

export default AccordionFilter
