import React, { useContext, createContext, useState, useEffect } from 'react'
import { IChildTypes } from '../types/childrenTypes'

const inputsDefault = [
  {
    title: 'RG',
    name: 'rg',
    value: '---',
    type: 'IDENTIDADE',
  },
  {
    title: 'receita médica',
    name: 'receita_medica',
    value: '---',
    type: 'PRESCRICAO',
  },
  {
    title: 'awb',
    name: 'awb',
    value: '---',
    type: 'PROCURACAO',
  },
  {
    title: 'comprovante residência',
    name: 'comprovante_residencia',
    value: '---',
    type: 'RESIDENCIAL',
  },
  {
    title: 'anvisa',
    name: 'anvisa',
    value: '---',
    type: 'ANVISA',
  },
  {
    title: 'comprovante vínculo',
    name: 'comprovante_veiculo',
    value: '---',
    type: 'VINCULO',
  },
]

interface inputsType {
  fileType: any
  title: string
  value?: string
  name: string
  type?: string
  status?: string
}

interface viewDocumentsContextType {
  inputsData: any
  setInputsData?: any
  viewDocuments?: any
  setViewDocuments?: any
  inputsSubmit?: any
  setInputsSubmit?: any
  handleCheckApi: any
  fileData?: any
  setFileData?: any
}

export const ViewDocumentsContext = createContext<viewDocumentsContextType>({
  inputsData: inputsDefault,
  handleCheckApi: () => null,
})

const ViewDocumentsProvider = ({ children }: IChildTypes) => {
  const [viewDocuments, setViewDocuments] = useState<any>({})
  const [inputsData, setInputsData] = useState(inputsDefault)
  const [inputsSubmit, setInputsSubmit] = useState({})
  const [fileData, setFileData] = useState<any>()

  const handleCheckApi = (documents: any[]) => {
    console.log('j', documents)
    const newInput = inputsData.map(item => {
      const verifyType = documents.filter((element: any) => element.type === item.type)
      return verifyType
        ? {
            ...item,
            value: verifyType[0]?.url || '---',
            status: verifyType[0]?.status,
            id: verifyType[0]?.id,
            fileType: verifyType[0]?.extension,
          }
        : { ...item }
    })
    setInputsData(newInput)
  }

  return (
    <ViewDocumentsContext.Provider
      value={{
        inputsSubmit,
        setInputsSubmit,
        setViewDocuments,
        viewDocuments,
        inputsData,
        setInputsData,
        handleCheckApi,
        fileData,
        setFileData,
      }}
    >
      {children}
    </ViewDocumentsContext.Provider>
  )
}

const useViewDocuments = () => useContext(ViewDocumentsContext)

export { ViewDocumentsProvider, useViewDocuments }
