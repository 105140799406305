import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  margin-top: 40px;
`
export const ButtonCopy = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 50%;
  height: 35px;
	margin-top: 40px;
	background-color: #C5581B;
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  cursor: pointer;
  text-align: center;
`

export const ButtonCopied = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  width: 50%;
  height: 35px;
	margin-top: 40px;
	background-color: #777777;
  color: #FFFFFF;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
`

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 80px;

  @media (max-width: 1751px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 80px;
  }
`

export const QrCodeinfos = styled.div`
  margin-left: 30px;
  font-size: 16px;
  color: #777777;

  p {
    padding-bottom: 10px;
  }

  @media (max-width: 1751px) {
    margin-top: 30px;
  }
`

export const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #E4E4E4;
  max-width: 300px;
  padding: 10px 30px;
  margin-bottom: 40px;
  text-align: center;
  color: #C5581B;
`

export const CountDownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    text-align: center;
    color: red;
  }
`