import useMediaQuery from 'hooks/useMediaQuery'
import { useOrderB2c } from 'hooks/useOrderB2c'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

interface ProductProps {
  translated_name: string
  price: number
  image: string
  id: number
}

export const Product = ({ id, translated_name, price, image }: ProductProps) => {
  const { addToCart, itens } = useOrderB2c()

  const alreadyInCart = itens.find(item => item.id === id)

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Style.Container
      alreadyInCart={alreadyInCart}
      onClick={() =>
        !alreadyInCart && addToCart(id, {
          translated_name,
          real_price: price,
          image,
          id: 0,
          quantity: 0,
        })
      }
    >
      <div className="product-name-image">
        <img src={image} alt="" />

        <p>{translated_name}</p>
      </div>

      <div className="product-price">
        {!isMobile && <span className="pipe">|</span>}

        <span className="price">
          {new Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol',
          }).format(price)}
        </span>
      </div>
    </Style.Container>
  )
}
