import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  align-items: ${props => (props.isMobile ? 'flex-start' : 'center')};
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  padding: 7px 42px 22px 33px;
  img {
    width: 67.08px;
    height: 70px;
  }

  svg {
    margin-right: 8px;
    color: ${props => props.theme.tertiary};
    cursor: pointer;
  }
`

export const ProductInfos = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${props => (props.isMobile ? '100%' : '50%')};

  img {
    margin-right: ${props => (props.isMobile ? 'auto' : '')};
  }
`

export const ProductName = styled.span<any>`
  margin-left: ${props => (props.isMobile ? '24.92px' : '14.92px')};
  ${({ isMobile }) =>
  !isMobile &&
  `
    padding-right: 92px;
  `}

  font-size: ${props => (props.isMobile ? '1rem' : '1rem')};
`

export const Quantity = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  width: 60px;
  height: 35px;

  .remove-button:hover {
    color: #115850;
  }

  span {
    font-size: 12px;
  }

  select {
    width: 58px;
    height: 30px;
    text-align: center;
    border: 1px solid rgba(17, 88, 80, 0.62);
    border-radius: 2px;
    margin-top: 16px;
  }
`

export const ProductPrice = styled.span<any>`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;

  ${({ isMobile }) =>
  isMobile &&
  `
    display: flex;
    flex-direction: column;
  `}

  span {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #115850;
  }
`

export const ProductTotal = styled(ProductPrice)``

export const ProductProperties = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-left: ${props => (props.isMobile ? '0px' : '30px')};

  width: ${props => (props.isMobile ? '100%' : '50%')};

  margin-top: ${props => (props.isMobile ? '16px' : '')};
`
