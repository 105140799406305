import AccordionFilter from "components/AccordionFilter"
import useMediaQuery from "hooks/useMediaQuery"
import { RowOrder } from "hooks/useOrderB2c"
import { BiLogInCircle } from "react-icons/bi"
import { useHistory } from "react-router-dom"
import { MOBILE_WIDTH } from "utils/constants"
import * as Styles from './styles'

interface Props {
  order: RowOrder
}

export const Accordion = ({order}: Props) => {
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const redirectToStatus = () => {
    history.push(`status_pedido/${order?.uuid}`)
  }

  return (
    <AccordionFilter title={order.id} date={order.createdAt}>
      <Styles.Container>
        <Styles.ContainerLeft>
          <Styles.OrderStatusTitle>
            <h3>Status do pedido:</h3>
          </Styles.OrderStatusTitle>
          <Styles.OrderPaymentStatusTitle>
            <h3>Status do pagamento</h3>
          </Styles.OrderPaymentStatusTitle>
          <Styles.OrderPeopleTitle>
            <h3>Paciente</h3>
          </Styles.OrderPeopleTitle>
        </Styles.ContainerLeft>
        <Styles.ContainerRight>
          <Styles.OrderStatus color={order.status_order_color}>
            <span>{order.status_order}</span>
          </Styles.OrderStatus>
          <Styles.OrderPaymentStatus color={order.status_payment_color}>
            <span>{order.status_payment}</span>
          </Styles.OrderPaymentStatus>
          <Styles.OrderPeople>
            <p>{order.name}</p>
          </Styles.OrderPeople>
        </Styles.ContainerRight>
      </Styles.Container>
      <hr />
      <Styles.Footer>
         <Styles.OrderValue>
            <span>{new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol'
            }).format(Number(order?.amount))}</span>
          </Styles.OrderValue>
          <Styles.OrderDetails onClick={redirectToStatus}>
            <span>detalhes <BiLogInCircle /></span>
          </Styles.OrderDetails>
      </Styles.Footer>

    </AccordionFilter>
  )
}

