import React from 'react'
import CardDashboard from 'components/CardDashboard'
import { gene, cuida, prescritores, ciencia, educa, vendas } from 'assets/svg'
import { GiMaterialsScience, GiDna1 } from "react-icons/gi";
import { FaHandHoldingMedical, FaNotesMedical } from "react-icons/fa";
import { MdOutlineLocalGroceryStore } from "react-icons/md";
import { TbSchool } from "react-icons/tb";

import * as Styles from './styles'

const Dashboard = () => {

  const cards = [
    { id: 1, name: 'GENE', href: '/gene', icon: GiDna1 },
    { id: 2, name: 'CUIDA', href: '/cuida', icon: FaHandHoldingMedical },
    { id: 3, name: 'PRESCRITORES', href: '/prescritores', icon: FaNotesMedical },
    { id: 4, name: 'CIÊNCIA', href: '/ciencia', icon: GiMaterialsScience },
    { id: 5, name: 'EDUCA', href: '/educa', icon: TbSchool },
    { id: 6, name: 'VENDAS', href: '/vendas', icon: MdOutlineLocalGroceryStore },
  ]

  return (
    <div className="py-6">
      <div className="mx-auto max-w-12xl px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      </div>
      <div className="mx-auto max-w-12xl px-4 sm:px-6 md:px-8 text-center overflow-y-auto space-y-5">
        <div className="py-4">
          <div className="h-full rounded-lg">
            <div className="mx-auto max-w-12xl px-4 sm:px-6 lg:px-8">
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6" aria-label="mother-card">
                {cards.map((card, index) => (
                  <a href={card.href} className="font-medium text-cyan-700 hover:text-cyan-900" aria-label="card">
                    <div key={card.id} className="overflow-hidden rounded-2xl bg-white shadow">
                      <div className="p-5">
                        <div className="flex">
                          <div className="flex-1">
                            <section className="hero container max-w-screen-lg mx-auto pb-6">
                              <card.icon className="h-24 w-24 rounded-full border border-blue-200 hover:border-blue-300
                              text-blue-200 hover:text-blue-300 mx-auto" />
                            </section>
                            <span className="text-lg font-base text-blue-200">{card.name}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
