import React from 'react'
import { OrderB2cProvider } from 'hooks/useOrderB2c'
import { ViewDocumentsProvider } from 'hooks/useViewDocument'
import { ToastContainer } from 'react-toastify'
import { IChildTypes } from '../types/childrenTypes'


const ContextProviders = ({ children }: IChildTypes) => {
  return (
    <>
      <OrderB2cProvider>
        <ViewDocumentsProvider>{children}</ViewDocumentsProvider>
      </OrderB2cProvider>
      <ToastContainer position="bottom-right" />
    </>

  )
}

export default ContextProviders
