import { MOBILE_WIDTH, routes } from "utils/constants"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"
import { useOrderB2c } from "hooks/useOrderB2c"
import useMediaQuery from "hooks/useMediaQuery"
import Loading from "components/Loading"
import { UserBar } from "./UserBar"
import { NewTable } from "./NewTable"
import * as Styles from "./styles"

export const Orders = () => {
  const history = useHistory()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const {getMyOrders, getCountOrders, clearOrder, loading, countAndTotalAmountsOrders} = useOrderB2c()

  const redirectToNewOrder: any = async () => {
    await clearOrder()
    history.push(routes.B2B_CREATE_ORDER_IDENTIFICATION)
  }


  useEffect(() => {
    getMyOrders()
    getCountOrders()
  }, [])

  return(
      <Styles.Container isMobile={isMobile}>
        {loading && <Loading loading/>}
        <UserBar />
        <Styles.Hr/>
        <Styles.Header isMobile={isMobile}>
          <Styles.ContainerButtons>
            <Styles.BtnNewOrder isMobile={isMobile} onClick={redirectToNewOrder}>NOVO PEDIDO</Styles.BtnNewOrder>
          </Styles.ContainerButtons>

          <Styles.InfoOrders isMobile={isMobile}>
            <Styles.OrdersAmount>{new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol'
            }).format(Number(countAndTotalAmountsOrders?.total_amount) || 0)}</Styles.OrdersAmount>
            <Styles.OrdersTotal isMobile={isMobile}>{countAndTotalAmountsOrders?.count || 0} pedidos</Styles.OrdersTotal>
          </Styles.InfoOrders>
        </Styles.Header>
        <Styles.Hr/>
        <NewTable />
      </Styles.Container>
  )
}
