import styled, { css } from 'styled-components'
import { theme } from 'design-cannect'

interface AccordionProps {
  isOpen: boolean
}

export const Container = styled.div<AccordionProps>`
  margin-top: 1rem;
  width: 100%;

  p{
    font-size: 0.75rem;
    font-weight: bold;
    color: ${props => props.isOpen ? 'white' : props.theme.colors.primary};
  }

  .date{
    margin-left: auto;
    span{
      font-size: 0.7rem;
      color: ${({ isOpen, theme }) => isOpen && 'white'};
    }
  }

  .header-accordion_filter {
    height: 40px;
    width: 100%;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    background: ${({ isOpen, theme }) => isOpen && theme.colors.primary};
    justify-content: space-between;
    transition: transform 0.2s;
    cursor: pointer;
    padding-left: 24px;
  }

  .button-accordion {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-right: ${theme.spacing.space2};
    font-size: ${theme.fontSize.md};

    padding: 0 16px;

    &:hover:not(:disabled) {
      transform: scale(1.1);
    }

    &:active:not(:disabled) {
      transform: scale(1.02);
      opacity: 0.8;
    }

    svg {
      color: ${({ isOpen, theme }) => (!isOpen ? theme.colors.primary : 'white')};
      transition: transform 0.3s;
      transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    }
  }
`
export const Wrapper = styled.div<AccordionProps>`
  overflow: auto;
  /* padding: 0 16px; */
  padding-top: ${({ isOpen }) => (isOpen ? '16px' : '0px')};
  width: 100%;
  position: relative;
  opacity: ${({ isOpen }) => (!isOpen ? '0' : '1')};
  max-height: ${({ isOpen }) => (isOpen ? '300px' : '0')};
  transition-duration: 0.6s;
  transition-property: opacity, max-height, padding-top;
  background-color: ${({ isOpen }) => isOpen && '#ececec'};

  .label {
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
