import { useOrderB2c } from "hooks/useOrderB2c"
import { CircleWavyWarning } from "assets/svg"
import { Modal } from "components/Modal"
import Loading from "components/Loading"
import useMediaQuery from "hooks/useMediaQuery"
import { MOBILE_WIDTH } from "utils/constants"
import * as Styles from "./styles"
import { StepButton } from "../StepsNavigator/StepButton"
import { Connector } from "../StepsNavigator/Connector"

import 'animate.css';
import { FormUser } from "./FormUser"
import { SearchUser } from "./SearchUser"

export const Identification = () => {

  const {loading, isModalOpen, setIsModalOpen, person, activeForm, setPersonAddress, setActiveForm, setPerson, cpfIsValid} = useOrderB2c()

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const crateUser = async () => {
    setActiveForm('data-user')
    setIsModalOpen(false)
    setPerson({
      cpf: person.cpf
    })
    setPersonAddress({})
  }

  return (
    <Styles.Container>
      {loading && <Loading loading/>}
      <Styles.StepsContainer>
        <div className='steps-name'>
          <StepButton step="identification" active disabled={false} />
          {!isMobile && <span>identificação</span>}
        </div>


        <Connector active={false} disabled />
        <div className='steps-name'>
          <StepButton step="select-products" active disabled />
          {!isMobile && <span>seleção de produtos</span>}
        </div>


        <Connector active={false} disabled />
        <div className='steps-name'>
          <StepButton step="cart" disabled active={false} />
          {!isMobile && <span>orçamento</span>}
        </div>

      </Styles.StepsContainer>

      {activeForm === 'search-user' && <SearchUser />}

      {activeForm === 'data-user' && <FormUser />}

      <Modal onRequestClose={() => closeModal()} isOpened={isModalOpen && cpfIsValid} className="modal-error">
        <Styles.ModalContainer>
          <Styles.ModalHeader>
            <img src={CircleWavyWarning} alt="" />
            CPF não encontrado
          </Styles.ModalHeader>
          <Styles.ModalContent>
              <p><button className="create-user" type="button" onClick={() => crateUser()}>Clique aqui</button> para cadastrar o PACIENTE</p>
              <button type="button" onClick={() => closeModal()}>Cancelar</button>
            </Styles.ModalContent>
        </Styles.ModalContainer>
      </Modal>
      <Modal onRequestClose={() => closeModal()} isOpened={isModalOpen && !cpfIsValid} className="modal-error">
        <Styles.ModalContainer>
          <Styles.ModalHeader>
            <img src={CircleWavyWarning} alt="" />
            Número de CPF inválido
          </Styles.ModalHeader>
          <Styles.ModalContent>
            <p>O número digitado não corresponde a um número de CPF válido. Por favor,
              revise os dados e tente novamente.
            </p>
            <button type="button" onClick={() => closeModal()}>Fechar</button>
          </Styles.ModalContent>
        </Styles.ModalContainer>
      </Modal>
    </Styles.Container>
  )
}
