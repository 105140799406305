import useMediaQuery from 'hooks/useMediaQuery'
import { useOrderB2c } from 'hooks/useOrderB2c'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Style from './styles'

export const Resume = () => {
  const {
    getDiscount,
    couponIsValid,
    couponValue,
    changeValueCoupon,
    itens,
    discount,
    shippingDiscount,
    orderCheckout,
  } = useOrderB2c()

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const defaultShippingValue = Number(orderCheckout?.shipping) || 140
  const frete = defaultShippingValue - shippingDiscount

  const statusDiscount = ''

  const resume = itens.reduce(
    (acc, item) => {
      acc.subtotal += item.real_price * item.quantity
      // acc.discount = acc.shippingDiscount + acc.productDiscount
      acc.total = acc.subtotal + frete - acc.discount
      return acc
    },
    {
      total: 0,
      subtotal: 0,
      discount,
      shippingDiscount: 0,
      productDiscount: 0,
    }
  )

  return (
    <Style.Container isMobile={isMobile}>
      <Style.Title>Resumo do Pedido</Style.Title>
      <Style.Prices isMobile={isMobile}>
        <Style.LeftContainer>
          <span>Subtotal</span>
          <span>Custo de importação</span>
          <span>Descontos</span>
        </Style.LeftContainer>
        <Style.RightContainer>
          <span className="cupom-badge">cupom de desconto</span>
          <div className="cupom-container">
            <div className="input-group">
              <Style.CupomInput
                isValid={couponIsValid}
                type="text"
                value={couponValue}
                id="couponValue"
                onChange={e => changeValueCoupon(e)}
              />
              {statusDiscount && <span className="status-cupom">{statusDiscount}</span>}
            </div>

            <Style.ButtonDiscount onClick={() => getDiscount(itens, couponValue)}>Validar</Style.ButtonDiscount>
          </div>
          <span>
            {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol',
            }).format(resume.subtotal)}
          </span>
          <span>
            {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol',
            }).format(frete)}
          </span>
          <span>
            -{' '}
            {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol',
            }).format(resume.discount)}
          </span>
        </Style.RightContainer>
      </Style.Prices>
      <hr />
      <Style.Total>
        <span className="total-title">Total</span>
        <span className="total">
          {new Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol',
          }).format(resume.total)}
        </span>
      </Style.Total>
    </Style.Container>
  )
}
