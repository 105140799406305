import React, { useEffect, useState } from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { useHistory } from 'react-router-dom'
import { routes, TOKEN_KEY, USER_CONFIG } from 'utils/constants'
import { defaultsHeadersAxios } from 'services/api'
import { deleteItem, getItem } from 'utils/persistenceUtils'
import * as Styles from './styles'

const Logaout: React.FC = () => {
const [user, setUser] = useState<any>('')

	const history = useHistory()

	const userLogout = () => {
    defaultsHeadersAxios('')
    deleteItem(TOKEN_KEY)
    deleteItem(USER_CONFIG)
    history.push(routes.LOGIN)
}

const items: MenuProps['items'] = [
  {
    label: (
      <Styles.LogoutButton onClick={userLogout}> Logout <LogoutOutlined /> </Styles.LogoutButton>
    ),
    key: '0',
  },
];

useEffect(() => {
  const user: any = getItem(USER_CONFIG)
  setUser(JSON.parse(user))
}, [])

  return (
    <Styles.LogoutContainer>
      <Dropdown menu={{ items }}>
        <Styles.UserButton type="button" onClick={(e) => e.preventDefault()}>
          <Space>
            <UserOutlined style={{fontSize: "18px"}} />
          </Space>
        </Styles.UserButton>
      </Dropdown>
      <Styles.LogoutUser>
       Olá, {user.name}
      </Styles.LogoutUser>
    </Styles.LogoutContainer>
  )
}

export default Logaout;