import { toast } from 'react-toastify'

export default () => {
  const ToastSuccess = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }
  const ToastError = (message: string) => {
    toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    })
  }

  return { ToastSuccess, ToastError }
}
