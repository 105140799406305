import cannectLoading from 'assets/gifs/cannect-loading.gif'
import Text from 'components/Text'
import * as Styles from './styles'

interface propsLoading {
  loading: boolean
}

const Loading = ({ loading }: propsLoading) => {
  return (
    <span>
      {loading && (
        <Styles.Overlay>
          <Styles.Container>
            <img src={cannectLoading} alt="" width="80px" height="80px" />
            <p>
              CARREGANDO...
            </p>
          </Styles.Container>
        </Styles.Overlay>
      )}
    </span>
  )
}

export default Loading
