import { theme } from 'design-cannect'
import styled from 'styled-components'
import { theme as brandTheme } from 'styles'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;

  ul {
    display: flex;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      height: 34px;
      width: 34px;
      border-radius: 8px;
      background: ${theme.colors.gray};
      color: ${theme.colors.tertiary};
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    li.selected {
      background: ${props => props.theme.colors.primary};
      color: white;
    }

    li.disabled,
    li.next,
    li.previous {
      display: none;
    }
  }
`
