import { format } from "date-fns"
import useMediaQuery from "hooks/useMediaQuery"
import { RowOrder } from "hooks/useOrderB2c"
import { BiLogInCircle } from "react-icons/bi"
import { useHistory } from "react-router-dom"
import { MOBILE_WIDTH, routes } from "utils/constants"
import * as Styles from "./styles"

interface Props {
order: RowOrder
}
export const RowTable = ({ order }: Props | any) => {
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const redirectToStatus = () => {
    history.push(`status_pedido/${order?.uuid}`)
  }
  return (
    <Styles.Container isMobile={isMobile}>
      <Styles.Data isMobile={isMobile}>
        {order?.id}
      </Styles.Data>
      <Styles.StatusOrder isMobile={isMobile} color={order?.status_order_color}>
        <span>{order?.status_order}</span>
      </Styles.StatusOrder>
      <Styles.StatusPayment isMobile={isMobile} color={order?.status_payment_color}>
      <span>{order?.status_payment}</span>
      </Styles.StatusPayment>
      <Styles.Date isMobile={isMobile}>
        <span>{format(new Date(order?.createdAt), 'dd/MM/yyyy')}</span>
        <span>{format(new Date(order?.createdAt), 'kk:mm')}</span>
      </Styles.Date>
      <Styles.People isMobile={isMobile}>
       {order?.name}
      </Styles.People>
      <Styles.Value isMobile={isMobile}>
      {new Intl.NumberFormat('pt-br', {
              style: 'currency',
              currency: 'BRL',
              currencyDisplay: 'symbol'
            }).format(Number(order?.amount))}
      </Styles.Value>
      <Styles.Details isMobile={isMobile}>
        <BiLogInCircle fill="#115850" onClick={redirectToStatus}/>
      </Styles.Details>
    </Styles.Container>
  )
}
