export const formatDate = (date: Date) => {
  const data = new Date(date)
  return `${data.toLocaleString('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })}`
}

export const formatHours = (date: Date) => {
  const data = new Date(date)
  return `${data.toLocaleString('pt-BR', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  })}`
}

export const formatDateUSD = (date: Date) => {
  const data = new Date(date)

  return `${data.getFullYear()}-${data.getDate()}-${data.getMonth()}`
}
export const formatDateAll = (date: Date) => {
  const data = new Date(date)
  return `${data.toLocaleString('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })}`
}

export const formatIsoDateToDisplay = (date: string) => {
  const data = new Date(date)
    const dia  = data.getDate().toString().padStart(2, '0')
    const mes  = (data.getMonth()+1).toString().padStart(2, '0')
    const ano  = data.getFullYear()
  return `${dia}/${mes}/${ano}`;
}

export function formatPhone(phone: any) {
  let phoneNumber = phone;
  let phoneFormated

  if(!phone) {
    phoneFormated = phoneNumber;
  } else {
    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (phoneNumber.length === 11) {
      phoneFormated = `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 7)}-${phoneNumber.substring(7, 11)}`;
    }
    if (phoneNumber.length === 10) {
      phoneFormated = `(${phoneNumber.substring(0, 2)}) ${phoneNumber.substring(2, 6)}-${phoneNumber.substring(6, 10)}`;
    }
    if (phoneNumber.length === 9) {
      phoneFormated = `${phoneNumber.substring(0, 5)}-${phoneNumber.substring(5, 9)}`;
    }
    if (phoneNumber.length === 8) {
      phoneFormated = `${phoneNumber.substring(0, 4)}-${phoneNumber.substring(4, 8)}`;
    }
  }
  return  phoneFormated;
}

export function formatCpf(cpf = ''): string {
  if (cpf.length >= 14) return cpf.slice(0, 14)

  let formattedCpf = cpf
  formattedCpf = clearMask(cpf)

  formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2')
  formattedCpf = formattedCpf.replace(/(\d{3})(\d)/, '$1.$2')
  formattedCpf = formattedCpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2')

  return formattedCpf
}

export function keepOnlyDigits(value: string) {
  return value.replace(/\D/g, '')
}

export function formatZipCode(zipCode = ''): string {
  if (!zipCode) {
    return ''
  }
  if (zipCode.length >= 9) return zipCode.slice(0, 9)

  let formattedZipCode = zipCode
  formattedZipCode = clearMask(formattedZipCode)
  formattedZipCode = formattedZipCode.replace(/(\d{5})(\d)/, '$1-$2')

  return formattedZipCode
}


export function clearMask(value: string): string {
  return value.replace(/\D/g, '')
}

export function formatBirthday(date = ''): string {
  let formattedBirthday = date

  if(!date || date === "Invalid date") {
    formattedBirthday = '00/00/0000'
  }
  if (formattedBirthday.length <= 10) {
    formattedBirthday = clearMask(formattedBirthday)

    formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
    formattedBirthday = formattedBirthday.replace(/(\d{2})(\d)/, '$1/$2')
  } else {
    formattedBirthday = formattedBirthday.slice(0, 10)
  }

  return formattedBirthday
}

export const formatReal = (value: number | string) => {
  return !!value && parseInt(value.toString(), 10)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

export const formatAddress = (
  address: string,
  address_number: string,
  address_district: string,
  address_city: string,
  address_state: string
) => {
  return `${address} ${address_number} ${address_district} ${address_city}  ${address_state}`
}

export function formatCard(v: string | any = '') {
  if (v.length <= 0) {
    return ''
  }

  // eslint-disable-next-line no-param-reassign
  v = v.replace(/\D/g, '')
  return v.match(/\d{1,4}/g).join(' ')
}

export function formatCardValidate(v: string | any = '') {
  if (v.length <= 0) {
    return ''
  }

  if (v.length >= 5) return v.slice(0, 5)
  // eslint-disable-next-line no-param-reassign
  v = v.replace(/\D/g, '')
  return v.match(/\d{1,2}/g).join('/')
}

export function clearQuotation(phone: string) {
  const clearPhone = phone.replace(/\D/g, '');
  const ddd = clearPhone.slice(0,2)
  let prefix1
  const prefix2 = clearPhone.slice(-4)
  let formatedPhone

  if (clearPhone.length === 10 || clearPhone.length === 11) {

  if(clearPhone.length === 10) {
    prefix1 = clearPhone.slice(2, 6)
  } else if (clearPhone.length === 11) {
    prefix1 = clearPhone.slice(2, 7)
  }
  formatedPhone = `${ddd} ${prefix1}-${prefix2}`
} else {
  formatedPhone = phone
}

return formatedPhone

}

export const normalizeName = (name: string | any) => {
  if (!name) {
    return name;
  }

  const names = name.split(' ');
  const nameArr = [];

  const alwaysLowerCase = ['de', 'da', 'das', 'do', 'dos', 'e', 'of'];

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < names.length; i++) {
    let lower;
    let upper;
    let normalize;

    if (alwaysLowerCase.indexOf(names[i]) > -1) {
      nameArr.push(names[i]);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (names[i] !== '') {
      lower = names[i].toLowerCase().substring(1);
      upper = names[i][0].toUpperCase();
      normalize = `${upper}${lower}`;

      if (i < names.length - 1 && alwaysLowerCase.indexOf(names[i + 1].toLowerCase()) > -1) {
        normalize += ` ${names[i + 1].toLowerCase()}`;
        // eslint-disable-next-line no-plusplus
        i++;
      }

      nameArr.push(normalize);
    }
  }

  const nameAdjusted = nameArr.join(' ');

  return nameAdjusted;
};


export function generateUniqueId(existingId?: number | string): number {
  const id = typeof existingId === 'string' ? Number(existingId) : existingId
  if (id !== undefined) {
    return (id % 1000) * 100 + Math.floor(Math.random() * 100)
  }
  const randomDigits1 = Math.floor(Math.random() * 900) + 100
  const randomDigits2 = Math.floor(Math.random() * 100)
  return randomDigits1 * 100 + randomDigits2
}

export const formatNewReal = (value: number | string) => {
  return (
    !!value &&
    parseFloat(value.toString()).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL'
    })
  )
}
