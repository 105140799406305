import React from 'react'
import { TextProps } from 'types/TextTypes'
import * as Styles from './styles'

const Texts = ({ type = 'md', children, bold, color, ...props }: React.PropsWithChildren<TextProps>) => {
  const handleTypeText = () => {
    switch (type) {
      case 'h1':
        return <h1 {...props}>{children}</h1>
      case 'h2':
        return <h2 {...props}>{children}</h2>
      default:
        return <p {...props}>{children}</p>
    }
  }

  return (
    <Styles.Container type={type || 'md'} color={color || 'black'} bold={bold}>
      { handleTypeText() }
    </Styles.Container>
  )
}
export default Texts
