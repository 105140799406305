import styled from "styled-components";

export const Container = styled.div`
  /* margin-top: 0.5rem; */
  /* margin-bottom: 1rem; */
  background-color: #FCFAFA;
  padding: 0.5rem 1rem;
`

export const List = styled.div`
  height: 250px;
  overflow-x: scroll;
  .list-products{
    width: 100% !important;
  }
`
