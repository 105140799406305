import styled from "styled-components";

export const Container = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '80%'};
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const Input = styled.input`
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #115850;
  border-radius: 4px;

  height: 50px;
  padding-left: 1rem;
`
