import styled from 'styled-components'
import { theme } from 'styles'

export const Container = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

interface ListProps {
  withMargin?: boolean
}

export const Header = styled.div<any>`
  width: ${props => props.isMobile ? '100%' : '80%'};
  display: flex;
  background-color: white;
  margin-top: 0.1rem;
  height: 80px;
  border-radius: 5px;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.1rem;

  .header-title{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    span{
      color: #115850;
      text-align: center;
      font-size: 13px;
      font-weight: 600;
    }
  }
`
export const ContentPagination = styled.div`
  margin-top: 20px;
`
